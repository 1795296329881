import axios from 'axios';
import { contextPath } from 'appConstants';
import { Invoice } from 'reducers/invoice/types';

class InvoiceService {
  async getInvoices(): Promise<Array<Invoice>> {
    return await axios.get(`${contextPath}/api/invoices`).then(response => response.data);
  }

  async createInvoice(invoice: Invoice): Promise<Invoice> {
    return await axios.post(`${contextPath}/api/invoices`, { ...invoice }).then(response => response.data);
  }

  async updateInvoice(invoice: Invoice): Promise<Invoice> {
    return await axios.put(`${contextPath}/api/invoices`, { ...invoice }).then(response => response.data);
  }

  async deleteInvoice(id: number): Promise<void> {
    return await axios.delete(`${contextPath}/api/invoices/${id}`).then(response => response.data);
  }

  async printInvoice(id: number, language: string = 'en'): Promise<Blob> {
    return await axios.get(`${contextPath}/api/invoices/print/${id}?language=${language}`, {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf'
      }
    }).then(response => {
      return new Blob([response.data], {
        type: 'application/pdf'
      });
    });
  }
}

export default new InvoiceService();
