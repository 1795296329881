import React, { FormEvent } from 'react';

import { Button, Form, Input } from 'antd';
import { useMessageSource } from 'react-message-source';
import { FormComponentProps } from 'antd/lib/form';
import { formItemLayout, tailFormItemLayout } from 'theme/formLayout';
import { Device } from 'reducers/device/types';

interface DeviceFormProps extends FormComponentProps {
  selectedDevice?: Device;
  onSaveDevice: (device: Device) => Promise<void | number>;
}

const DeviceForm: React.FunctionComponent<DeviceFormProps> = (props) => {

  const { getMessage } = useMessageSource('page.devices.form');

  const { selectedDevice, onSaveDevice } = props;
  const { getFieldDecorator } = props.form;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const deviceData = values;
        if (selectedDevice) {
          deviceData.id = selectedDevice.id;
        }
        onSaveDevice(deviceData).then(() => props.form.resetFields());
      }
    });
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label={getMessage('manufacturer')} hasFeedback>
        {getFieldDecorator('manufacturer', {
          initialValue: selectedDevice && selectedDevice.manufacturer,
          rules: [
            {
              required: true,
              message: getMessage('manufacturer.error')
            }
          ]
        })(<Input/>)}
      </Form.Item>
      <Form.Item label={getMessage('model')} hasFeedback>
        {getFieldDecorator('model', {
          initialValue: selectedDevice && selectedDevice.model,
          rules: [
            {
              required: true,
              message: getMessage('model.error')
            }
          ]
        })(<Input/>)}
      </Form.Item>
      <Form.Item label={getMessage('deviceName')} hasFeedback>
        {getFieldDecorator('deviceName', {
          initialValue: selectedDevice && selectedDevice.deviceName,
          rules: [
            {
              required: true,
              message: getMessage('deviceName.error')
            }
          ]
        })(<Input/>)}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          {getMessage('button.submit')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create<DeviceFormProps>({ name: 'deviceForm' })(DeviceForm);
