import {
  User,
  USERS_FETCHED_ACTION_TYPE,
  USER_ADDED_ACTION_TYPE,
  USER_UPDATED_ACTION_TYPE,
  USER_DELETED_ACTION_TYPE,
  UsersFetchedAction,
  UserAddedAction,
  UserDeletedAction,
  UserUpdatedAction,
  UserAction
} from 'reducers/user/types';

export const usersFetchedAction = (users: Array<User>): UsersFetchedAction => ({
  type: USERS_FETCHED_ACTION_TYPE,
  users
});

export const userAddedAction = (user: User): UserAddedAction => ({
  type: USER_ADDED_ACTION_TYPE,
  user
});

export const userUpdatedAction = (user: User): UserUpdatedAction => ({
  type: USER_UPDATED_ACTION_TYPE,
  user
});

export const userDeletedAction = (id: number): UserDeletedAction => ({
  type: USER_DELETED_ACTION_TYPE,
  id
});

const userReducer = (state: Array<User>, action: UserAction) => {
  switch (action.type) {
    case USERS_FETCHED_ACTION_TYPE:
      return action.users;
    case USER_ADDED_ACTION_TYPE:
      return [...state, action.user];
    case USER_UPDATED_ACTION_TYPE:
      return state.map(user => {
        if (user.id === action.user.id) {
          return action.user;
        }
        return user;
      });
    case USER_DELETED_ACTION_TYPE:
      return state.filter(user => user.id !== action.id);
    default:
      return state;
  }
};

export default userReducer;
