import { State } from 'reducers/types';

export const initialState: State = {
  global: {
    language: 'en'
  },
  customers: [],
  devices: [],
  parts: [],
  partCategories: [],
  interventions: [],
  invoices: [],
  resources: [],
  users: []
};
