import React, { ReducerAction, createContext, Dispatch, Reducer, useContext, useReducer } from 'react';
import { initialState } from 'reducers/initialAppState';
import { State } from 'reducers/types';

type ContextValue = [State, Dispatch<ReducerAction<any>>];

interface StateProviderProps {
  reducer: Reducer<State, ReducerAction<any>>;
  initialState: State;
  children: React.ReactNode
}

export const StateContext = createContext<ContextValue>([initialState, (action) => action]);

export const StateProvider = ({ reducer, initialState, children }: StateProviderProps) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

export const useStateValue = (): ContextValue => useContext(StateContext);
