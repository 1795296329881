import React, { useContext } from 'react';
import { RouteComponentProps, Redirect } from '@reach/router';
import { Route, LoginRoute } from 'pages/routes';
import Forbidden from 'pages/Forbidden';
import { AuthenticationContext, Principal } from './AuthenticationContext';

function canPrincipalAccessRoute(principal?: Principal, route?: Route) {
  return true;
}

interface Props extends RouteComponentProps {
  route: Route;
}

function PrivateRoute({ route, ...rest }: Props) {
  const authContext = useContext(AuthenticationContext);
  const principal = authContext.principal;
  const Component = route.component;
  canPrincipalAccessRoute(principal, route);

  if (!principal) {
    return <Redirect to={LoginRoute.path} noThrow />;
  } else if (canPrincipalAccessRoute(principal, route)) {
    return <Component {...rest} />;
  }
  return <Forbidden />;
}

export { PrivateRoute };
