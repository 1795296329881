import axios from 'axios';
import { contextPath } from 'appConstants';
import { Intervention } from 'reducers/intervention/types';

class InterventionService {
  async getInterventions(): Promise<Array<Intervention>> {
    return await axios.get(`${contextPath}/api/interventions`).then(response => response.data);
  }

  async getInterventionById(id: number): Promise<Intervention> {
    return await axios.get(`${contextPath}/api/interventions/${id}`).then(response => response.data);
  }

  async createIntervention(intervention: Intervention): Promise<Intervention> {
    return await axios.post(`${contextPath}/api/interventions`, { ...intervention }).then(response => response.data);
  }

  async updateIntervention(intervention: Intervention): Promise<Intervention> {
    return await axios.put(`${contextPath}/api/interventions`, { ...intervention }).then(response => response.data);
  }

  async deleteIntervention(id: number): Promise<void> {
    return await axios.delete(`${contextPath}/api/interventions/${id}`).then(response => response.data);
  }

  async printIntervention(id: number, language: string = 'en'): Promise<Blob> {
    return await axios.get(`${contextPath}/api/interventions/print/${id}?language=${language}`, {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf'
      }
    }).then(response => {
      return new Blob([response.data], {
        type: 'application/pdf'
      });
    });
  }
}

export default new InterventionService();
