import React, { useState } from 'react';
import { Button, Modal, Table, Tag } from 'antd';
import { useMessageSource } from 'react-message-source';
import { Customer } from 'reducers/customer/types';

interface CustomersTableProps {
  customers: Array<Customer>;
  onDeleteCustomer: (id: number) => Promise<void>;
  onEditCustomer: (id: number) => void;
}

export default (props: CustomersTableProps) => {

  const [state, setState] = useState({ modalVisible: false, editingId: -1 });
  const { getMessage } = useMessageSource('page.customers');

  const handleDeleteButtonClick = (id: number | undefined) => {
    if (id) {
      setState({ modalVisible: true, editingId: id });
    }
  };

  const handleEditButtonClick = (id: number | undefined) => {
    if (id) {
      props.onEditCustomer(id);
    }
  };

  const closeModal = () => {
    setState({ modalVisible: false, editingId: -1 });
  };

  const deleteCustomer = (id: number) => {
    props.onDeleteCustomer(id).then(closeModal);
  };

  const columns = [
    {
      title: getMessage('column.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: getMessage('column.address'),
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: getMessage('column.taxNumber'),
      dataIndex: 'taxNumber',
      key: 'taxNumber'
    },
    {
      title: getMessage('column.contacts'),
      dataIndex: 'contacts',
      key: 'contacts',
      render: (contacts: Array<string>) => (
        <span>
          {contacts.map(contact => {
            return (
              <Tag color={'geekblue'} key={contact}>
                {contact}
              </Tag>
            );
          })}
        </span>
      )
    },
    {
      title: getMessage('column.actions'),
      key: 'action',
      render: (_: any, record: Customer) => (
        <span key={record.id}>
          <Button type="link" size="small" onClick={(() => handleEditButtonClick(record.id))}>
            {getMessage('action.edit')}
          </Button>
          <Button type="link" size="small" onClick={(() => handleDeleteButtonClick(record.id))}>
            {getMessage('action.delete')}
          </Button>
        </span>
      )
    }
  ];

  return (
    <>
      <Table dataSource={props.customers} columns={columns} rowKey="id"/>
      <Modal
        title={getMessage('modal.delete.title')}
        style={{ top: 20 }}
        visible={state.modalVisible}
        onOk={() => deleteCustomer(state.editingId)}
        onCancel={() => closeModal()}
      >
        <p>{getMessage('modal.delete.text')}</p>
      </Modal>
    </>
  );
}
