import React, { useState } from 'react';
import { Button, Modal, Table } from 'antd';
import { useMessageSource } from 'react-message-source';
import { Device } from 'reducers/device/types';

interface DevicesTableProps {
  devices: Array<Device>;
  onDeleteDevice: (id: number) => Promise<void>;
  onEditDevice: (id: number) => void;
}

export default (props: DevicesTableProps) => {

  const [state, setState] = useState({ modalVisible: false, editingId: -1 });
  const { getMessage } = useMessageSource('page.devices');

  const handleDeleteButtonClick = (id: number | undefined) => {
    if (id) {
      setState({ modalVisible: true, editingId: id });
    }
  };

  const handleEditButtonClick = (id: number | undefined) => {
    if (id) {
      props.onEditDevice(id);
    }
  };

  const closeModal = () => {
    setState({ modalVisible: false, editingId: -1 });
  };

  const deleteDevice = (id: number) => {
    props.onDeleteDevice(id).then(closeModal);
  };

  const columns = [
    {
      title: getMessage('column.manufacturer'),
      dataIndex: 'manufacturer',
      key: 'manufacturer'
    },
    {
      title: getMessage('column.model'),
      dataIndex: 'model',
      key: 'model'
    },
    {
      title: getMessage('column.deviceName'),
      dataIndex: 'deviceName',
      key: 'deviceName'
    },
    {
      title: getMessage('column.actions'),
      key: 'action',
      render: (_: any, record: Device) => (
        <span>
          <Button type="link" size="small" onClick={(() => handleEditButtonClick(record.id))}>
            {getMessage('action.edit')}
          </Button>
          <Button key={record.id} type="link" size="small" onClick={(() => handleDeleteButtonClick(record.id))}>
            {getMessage('action.delete')}
          </Button>
        </span>
      )
    }
  ];

  return (
    <>
      <Table dataSource={props.devices} columns={columns} rowKey="id"/>
      <Modal
        title={getMessage('modal.delete.title')}
        style={{ top: 20 }}
        visible={state.modalVisible}
        onOk={() => deleteDevice(state.editingId)}
        onCancel={() => closeModal()}
      >
        <p>{getMessage('modal.delete.text')}</p>
      </Modal>
    </>
  );
}
