import { ReducerAction } from 'react';
import { State } from 'reducers/types';
import globalDataReducer from 'reducers/global/globalDataReducer';
import customersReducer from 'reducers/customer/customerReducer';
import deviceReducer from 'reducers/device/deviceReducer';
import partReducer from 'reducers/part/partReducer';
import interventionReducer from 'reducers/intervention/interventionReducer';
import invoiceReducer from 'reducers/invoice/invoiceReducer';
import resourceReducer from 'reducers/resource/resourceReducer';
import partCategoryReducer from 'reducers/part-category/partCategoryReducer';
import userReducer from 'reducers/user/userReducer';
import { GlobalAction } from 'reducers/global/types';
import { CustomerAction } from 'reducers/customer/types';
import { DeviceAction } from 'reducers/device/types';
import { PartAction } from 'reducers/part/types';
import { InterventionAction } from 'reducers/intervention/types';
import { ResourceAction } from 'reducers/resource/types';
import { PartCategoryAction } from 'reducers/part-category/types';
import { InvoiceAction } from 'reducers/invoice/types';
import { UserAction } from 'reducers/user/types';

export const rootReducer = (
  {
    global,
    customers,
    devices,
    parts,
    partCategories,
    interventions,
    invoices,
    resources,
    users
  }: State,
  action: ReducerAction<any>
): State => ({
  global: globalDataReducer(global, action as GlobalAction),
  customers: customersReducer(customers, action as CustomerAction),
  devices: deviceReducer(devices, action as DeviceAction),
  parts: partReducer(parts, action as PartAction),
  partCategories: partCategoryReducer(partCategories, action as PartCategoryAction),
  interventions: interventionReducer(interventions, action as InterventionAction),
  invoices: invoiceReducer(invoices, action as InvoiceAction),
  resources: resourceReducer(resources, action as ResourceAction),
  users: userReducer(users, action as UserAction)
});
