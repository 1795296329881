import React from 'react';
import { Form, Modal } from 'antd';
import { useMessageSource } from 'react-message-source';
import { FormComponentProps } from 'antd/lib/form';
import { ChangePasswordValues } from 'reducers/user/types';
import PasswordFields from 'user/PasswordFields';

interface ChangePasswordModalProps extends FormComponentProps {
  visible: boolean;
  onOk: (values: ChangePasswordValues) => Promise<void>;
  onCancel: () => void;
}

const ChangePasswordModal: React.FunctionComponent<ChangePasswordModalProps> = (props) => {
  const { getMessage } = useMessageSource('modal.password');

  const handleOk = () => {
    props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }
      props.onOk(values).then(() => props.form.resetFields());
    });
  };

  const handleCancel = () => {
    props.form.resetFields();
    props.onCancel();
  };

  return (
    <Modal
      title={getMessage('title')}
      visible={props.visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form layout="vertical">
        <PasswordFields form={props.form} />
      </Form>
    </Modal>
  )
};

export default Form.create<ChangePasswordModalProps>({ name: 'changePasswordForm' })(ChangePasswordModal);
