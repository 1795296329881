import * as React from 'react';
import { contextPath } from 'appConstants';
import Dashboard from 'pages/Dashboard';
import Users from 'pages/Users';
import Customers from 'pages/Customers';
import Devices from 'pages/Devices';
import Parts from 'pages/Parts';
import Services from 'pages/Interventions';
import Reports from 'pages/Reports';
import Login from 'pages/Login';
import InterventionDetail from 'intervention/InterventionDetail';
import Resources from 'pages/Resources';
import Invoices from 'pages/Invoices';

export interface Route {
  title: string;
  name: string;
  path: string;
  iconType?: string;
  component: React.FunctionComponent<any> | React.ComponentClass<any>;
  hidden?: boolean;
  public?: boolean;
}

const DashboardRoute: Route = {
  component: Dashboard,
  name: 'Dashboard',
  path: `${contextPath}/`,
  iconType: 'layout',
  title: 'page.dashboard.title'
};

const UsersRoute: Route = {
  component: Users,
  name: 'Users',
  path: `${contextPath}/users`,
  iconType: 'user',
  title: 'page.users.title'
};

const CustomersRoute: Route = {
  component: Customers,
  name: 'Customers',
  path: `${contextPath}/customers`,
  iconType: 'customer-service',
  title: 'page.customers.title'
};

const DevicesRoute: Route = {
  component: Devices,
  name: 'Devices',
  path: `${contextPath}/devices`,
  iconType: 'mobile',
  title: 'page.devices.title'
};

const PartsRoute: Route = {
  component: Parts,
  name: 'Parts',
  path: `${contextPath}/parts`,
  iconType: 'setting',
  title: 'page.parts.title'
};

const ServicesRoute: Route = {
  component: Services,
  name: 'Services',
  path: `${contextPath}/services`,
  iconType: 'calendar',
  title: 'page.services.title'
};

const InvoicesRoute: Route = {
  component: Invoices,
  name: 'Invoices',
  path: `${contextPath}/invoices`,
  iconType: 'container',
  title: 'page.invoices.title'
};

const ServiceDetailsRoute: Route = {
  component: InterventionDetail,
  name: 'ServiceDetail',
  path: `${ServicesRoute.path}/:id`,
  hidden: true,
  title: 'page.services.details.title'
};

const ReportsRoute: Route = {
  component: Reports,
  name: 'Reports',
  path: `${contextPath}/reports`,
  iconType: 'snippets',
  title: 'page.reports.title'
};

const ResourcesRoute: Route = {
  component: Resources,
  name: 'Resources',
  path: `${contextPath}/resources`,
  iconType: 'table',
  title: 'page.resources.title'
};

export const LoginRoute: Route = {
  component: Login,
  name: 'Login',
  path: `${contextPath}/login`,
  title: 'page.login.title',
  hidden: true,
  public: true
};

const allPages = [
  LoginRoute,
  DashboardRoute,
  UsersRoute,
  CustomersRoute,
  DevicesRoute,
  PartsRoute,
  ServicesRoute,
  ServiceDetailsRoute,
  InvoicesRoute,
  ReportsRoute,
  ResourcesRoute
];
export { allPages };
