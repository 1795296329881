export interface Theme {
    text: string;
    hint: string;
    primary: string;
    secondary: string;
    accent: string;
    background: string;
}

export const defaultTheme: Theme = {
    text: '#5D5C61',
    hint: '#938E94',
    primary: '#7395AE',
    secondary: '#557A95',
    accent: '#B0A295',
    background: '#F9F9F9'
}
