import {
  Part,
  PARTS_FETCHED_ACTION_TYPE,
  PART_ADDED_ACTION_TYPE,
  PART_UPDATED_ACTION_TYPE,
  PART_DELETED_ACTION_TYPE,
  PartsFetchedAction,
  PartAddedAction,
  PartDeletedAction,
  PartUpdatedAction,
  PartAction
} from 'reducers/part/types';
import { BOOTSTRAP_ACTION_TYPE } from 'reducers/types';

export const partsFetchedAction = (parts: Array<Part>): PartsFetchedAction => ({
  type: PARTS_FETCHED_ACTION_TYPE,
  parts
});

export const partAddedAction = (part: Part): PartAddedAction => ({
  type: PART_ADDED_ACTION_TYPE,
  part
});

export const partUpdatedAction = (part: Part): PartUpdatedAction => ({
  type: PART_UPDATED_ACTION_TYPE,
  part
});

export const partDeletedAction = (id: number): PartDeletedAction => ({
  type: PART_DELETED_ACTION_TYPE,
  id
});

const partReducer = (state: Array<Part>, action: PartAction) => {
  switch (action.type) {
    case BOOTSTRAP_ACTION_TYPE:
      return action.state.parts;
    case PARTS_FETCHED_ACTION_TYPE:
      return action.parts;
    case PART_ADDED_ACTION_TYPE:
      return [...state, action.part];
    case PART_UPDATED_ACTION_TYPE:
      return state.map(part => {
        if (part.id === action.part.id) {
          return action.part;
        }
        return part;
      });
    case PART_DELETED_ACTION_TYPE:
      return state.filter(part => part.id !== action.id);
    default:
      return state;
  }
};

export default partReducer;
