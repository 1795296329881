import React, { useState } from 'react';
import { Button, Modal, Table, Tag } from 'antd';
import { useMessageSource } from 'react-message-source';
import ChangePasswordModal  from 'user/ChangePasswordModal';
import { ChangePasswordValues, User } from 'reducers/user/types';

interface UsersTableProps {
  users: Array<User>;
  onDeleteUser: (id: number) => Promise<void>;
  onEditUser: (id: number) => void;
  onChangePassword: (id:number, values: ChangePasswordValues) => Promise<void>;
}

interface ChangePasswordModalState {
  visible: boolean;
  userId: number | undefined;
}

export default (props: UsersTableProps) => {

  const [state, setState] = useState({ modalVisible: false, editingId: -1 });
  const [changePasswordModalState, setChangePasswordModalState] =
    useState<ChangePasswordModalState>({ visible: false, userId: undefined });

  const { getMessage } = useMessageSource('page.users');

  const handleDeleteButtonClick = (id: number | undefined) => {
    if (id) {
      setState({ modalVisible: true, editingId: id });
    }
  };

  const handleChangePasswordClick = (id: number) => {
    setChangePasswordModalState({ visible: true, userId: id });
  };

  const handleEditButtonClick = (id: number) => {
    props.onEditUser(id);
  };

  const closeModal = () => {
    setState({ modalVisible: false, editingId: -1 });
  };

  const deleteUser = (id: number) => {
    props.onDeleteUser(id).then(closeModal);
  };

  const closeChangePasswordModal = () => {
    setChangePasswordModalState({ visible: false, userId: undefined });
  };

  const changePassword = (userId: number | undefined, values: ChangePasswordValues) => {
    if (userId) {
      return props.onChangePassword(userId, values).then(closeChangePasswordModal);
    }
    return Promise.resolve();
  };

  const columns = [
    {
      title: getMessage('column.firstName'),
      dataIndex: 'firstName',
      key: 'firstName'
    },
    {
      title: getMessage('column.lastName'),
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: getMessage('column.username'),
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: getMessage('column.roles'),
      dataIndex: 'roles',
      key: 'roles',
      render: (roles: Array<string>) => (
        <span>
          {roles.map(role => {
            return (
              <Tag color={'geekblue'} key={role}>
                {role}
              </Tag>
            );
          })}
        </span>
      )
    },
    {
      title: getMessage('column.actions'),
      key: 'action',
      render: (_: any, record: User) => (
        <span key={record.id}>
          <Button type="link" size="small" onClick={(() => handleEditButtonClick(record.id))}>
            {getMessage('action.edit')}
          </Button>
          <Button type="link" size="small" onClick={(() => handleChangePasswordClick(record.id))}>
            {getMessage('action.change.password')}
          </Button>
          <Button type="link" size="small" onClick={(() => handleDeleteButtonClick(record.id))}>
            {getMessage('action.delete')}
          </Button>
        </span>
      )
    }
  ];

  return (
    <>
      <Table dataSource={props.users} columns={columns} rowKey="id"/>
      <Modal
        title={getMessage('modal.delete.title')}
        style={{ top: 20 }}
        visible={state.modalVisible}
        onOk={() => deleteUser(state.editingId)}
        onCancel={() => closeModal()}
      >
        <p>{getMessage('modal.delete.text')}</p>
      </Modal>
      <ChangePasswordModal
        visible={changePasswordModalState.visible}
        onOk={(values) => changePassword(changePasswordModalState.userId, values)}
        onCancel={closeChangePasswordModal}
      />
    </>
  );
}
