import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Descriptions, Tag } from 'antd';
import { useMessageSource } from 'react-message-source';
import dateFormat from 'dateformat';
import InterventionService from './InterventionService';
import { Intervention, PartIntervention } from 'reducers/intervention/types';

interface InterventionDetailProps extends RouteComponentProps{
  id: number;
}

interface StringMap {
  [key: string]: any;
}
const statusTagColorMap: StringMap = {
  OPEN: 'blue',
  FIXED: 'green',
  NOT_FIXABLE: 'red',
  WORKING: 'purple'
};

export default (props: InterventionDetailProps) => {

  const { getMessage } = useMessageSource('page.interventions.details');
  const [intervention, setIntervention] = useState<Partial<Intervention>>({});

  useEffect(() => {
    InterventionService.getInterventionById(props.id).then(intervention => {
      setIntervention(intervention);
    })
  }, [props.id]);


  return (
    <Descriptions title={`${getMessage('title')} [${intervention.id}]`} bordered column={1}>
      <Descriptions.Item label={getMessage('customer.name')}>
        <span>{intervention.customerName}</span>
      </Descriptions.Item>
      <Descriptions.Item label={getMessage('customer.contact')}>
        <span>{intervention.customerContact}</span>
      </Descriptions.Item>
      <Descriptions.Item label={getMessage('mobile.model')}>
        <span>{intervention && intervention.mobileModel}</span>
      </Descriptions.Item>
      <Descriptions.Item label={getMessage('mobile.imei')}>
        <span>{intervention && intervention.imei}</span>
      </Descriptions.Item>
      <Descriptions.Item label={getMessage('mobile.unlockCode')}>
        <span>{intervention && intervention.unlockCode}</span>
      </Descriptions.Item>
      <Descriptions.Item label={getMessage('agreed.interventionDate')}>
        <span>{intervention.interventionDate && dateFormat(intervention.interventionDate, 'dd.mm.yyyy HH:MM')}</span>
      </Descriptions.Item>
      <Descriptions.Item label={getMessage('agreed.price')}>
        <span>{intervention.agreedPrice}</span>
      </Descriptions.Item>
      <Descriptions.Item label={getMessage('agreed.problemDescription')}>
        <span>{intervention.problemDescription}</span>
      </Descriptions.Item>
      <Descriptions.Item label={getMessage('agreed.note')}>
        <span>{intervention.note}</span>
      </Descriptions.Item>
      <Descriptions.Item label={getMessage('interventionDetails')}>
        <span>{intervention.interventionDetails}</span>
      </Descriptions.Item>
      <Descriptions.Item label={getMessage('usedParts')}>
        <div>
          {intervention.usedParts && intervention.usedParts.map((usedPart: PartIntervention) => (
            <Tag color={'geekblue'} key={usedPart.partName}>{usedPart.quantity}&nbsp;x&nbsp;{usedPart.partName}</Tag>
          ))}
        </div>
      </Descriptions.Item>
      <Descriptions.Item label={getMessage('price')}>
        <span>{intervention.price}</span>
      </Descriptions.Item>
      <Descriptions.Item label={getMessage('status')}>
        <Tag color={statusTagColorMap[intervention.status || 'OPEN']}>{getMessage(`status.${intervention.status}`)}</Tag>
      </Descriptions.Item>
      <Descriptions.Item label={getMessage('pickUpDate')}>
        <span>{intervention.pickUpDate && dateFormat(intervention.pickUpDate, 'dd.mm.yyyy HH:MM')}</span>
      </Descriptions.Item>
    </Descriptions>
  );
};