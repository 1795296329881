import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { RouteComponentProps } from '@reach/router';
import { useMessageSource } from 'react-message-source';
import InterventionsTable from 'intervention/InterventionsTable';
import InterventionForm from 'intervention/InterventionForm';
import { useStateValue } from 'reducers/poorMansRedux';
import { Intervention } from 'reducers/intervention/types';
import InterventionService from 'intervention/InterventionService';
import {
  interventionsFetchedAction,
  interventionAddedAction,
  interventionUpdatedAction,
  interventionDeletedAction
} from 'reducers/intervention/interventionReducer';

const Interventions: React.FC<RouteComponentProps> = (props) => {

  const [{interventions, devices, parts}, dispatch] = useStateValue();
  const [editingIntervention, setEditingIntervention] = useState<Intervention>();
  const { getMessage, getMessageWithNamedParams } = useMessageSource('page.interventions');

  const handleEditIntervention = (id: number) => {
    const interventionById = interventions.find(intervention => intervention.id === id);
    setEditingIntervention(interventionById);
  };

  const handleDeleteIntervention = (id: number) => {
    return InterventionService.deleteIntervention(id).then(() => {
      return dispatch(interventionDeletedAction(id));
    })
  };

  const handleSaveIntervention = (intervention: Intervention): Promise<void> => {
    if (intervention.id) {
      return InterventionService.updateIntervention(intervention).then((updatedIntervention) => {
        setEditingIntervention(undefined);
        dispatch(interventionUpdatedAction(updatedIntervention));
      });
    } else {
      return InterventionService.createIntervention(intervention).then(addedIntervention => {
        dispatch(interventionAddedAction(addedIntervention));
      });
    }
  };

  const handleNewClicked = () => {
    setEditingIntervention(undefined);
  };

  useEffect(() => {
    InterventionService.getInterventions().then(interventions => {
      dispatch(interventionsFetchedAction(interventions));
    });
  }, [dispatch]);

  const formKey = editingIntervention ? editingIntervention.id : 'new';

  const title = editingIntervention
    ? getMessageWithNamedParams('edit.title', { id: editingIntervention.id})
    : getMessage('add.title');

  return (
    <>
      <InterventionsTable
        navigate={props.navigate}
        interventions={interventions}
        onEditIntervention={handleEditIntervention}
        onDeleteIntervention={handleDeleteIntervention}
      />
      <Typography.Title level={2}>{title}</Typography.Title>
      <InterventionForm
        key={formKey}
        devices={devices}
        parts={parts}
        selectedIntervention={editingIntervention}
        onSaveIntervention={handleSaveIntervention}
        onNewClicked={handleNewClicked}
      />
    </>
  );
};

export default Interventions;
