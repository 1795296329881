
export const CHANGE_LANGUAGE_ACTION_TYPE = 'GLOBAL@CHANGE_LANGUAGE';

export interface GlobalDataState {
  language: string;
}

export interface LanguageAction {
  type: typeof CHANGE_LANGUAGE_ACTION_TYPE;
  language: string;
}

export type GlobalAction = LanguageAction;
