import {
  Device,
  DEVICE_ADDED_ACTION_TYPE,
  DEVICE_DELETED_ACTION_TYPE,
  DEVICE_UPDATED_ACTION_TYPE,
  DEVICES_FETCHED_ACTION_TYPE,
  DeviceAction,
  DevicesFetchedAction,
  DeviceAddedAction,
  DeviceUpdatedAction,
  DeviceDeletedAction
} from 'reducers/device/types';
import { BOOTSTRAP_ACTION_TYPE } from 'reducers/types';

export const devicesFetchedAction = (devices: Array<Device>): DevicesFetchedAction => ({
  type: DEVICES_FETCHED_ACTION_TYPE,
  devices
});

export const deviceAddedAction = (device: Device): DeviceAddedAction => ({
  type: DEVICE_ADDED_ACTION_TYPE,
  device
});

export const deviceUpdatedAction = (device: Device): DeviceUpdatedAction => ({
  type: DEVICE_UPDATED_ACTION_TYPE,
  device
});

export const deviceDeletedAction = (id: number): DeviceDeletedAction => ({
  type: DEVICE_DELETED_ACTION_TYPE,
  id
});

const deviceReducer = (state: Array<Device>, action: DeviceAction) => {
  switch (action.type) {
    case BOOTSTRAP_ACTION_TYPE:
      return action.state.devices;
    case DEVICES_FETCHED_ACTION_TYPE:
      return action.devices;
    case DEVICE_ADDED_ACTION_TYPE:
      return [...state, action.device];
    case DEVICE_UPDATED_ACTION_TYPE:
      return state.map(device => {
        if (device.id === action.device.id) {
          return action.device;
        }
        return device;
      });
    case DEVICE_DELETED_ACTION_TYPE:
      return state.filter(device => device.id !== action.id);
    default:
      return state;
  }
};

export default deviceReducer;
