import React, { useState } from 'react';
import AuthenticationService from 'security/AuthenticationService';

const SESSION_STORAGE_KEY: string = 'USER_DATA';
export const TOKEN_STORAGE_KEY: string = 'TOKEN_DATA';
export interface Principal {
  username: string;
  roles: string[];
}
export interface AuthenticationContextShape {
  principal?: Principal;
  login: (principal: Principal, token: string) => void;
  logout: () => void;
}
const AuthenticationContextShape: AuthenticationContextShape = {
  principal: undefined,
  login: () => {},
  logout: () => {}
};
const AuthenticationContext = React.createContext(AuthenticationContextShape);

function AuthenticationContextProvider({ children }: { children: React.ReactNode }) {
  const [principal, setPrincipal] = useState(JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY) as string));

  const login = (user: Principal, token: string): void => {
    setPrincipal(user);
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(user));
    sessionStorage.setItem(TOKEN_STORAGE_KEY, token);
  };

  const logout = (): void => {
    setPrincipal(null);
    sessionStorage.removeItem(SESSION_STORAGE_KEY);
    sessionStorage.removeItem(TOKEN_STORAGE_KEY);
    AuthenticationService.deregisterToken();
  };

  const contextValue: AuthenticationContextShape = {
    principal,
    login,
    logout
  };

  return <AuthenticationContext.Provider value={contextValue}>{children}</AuthenticationContext.Provider>;
}

const withAuthenticationContext = (Component: React.FunctionComponent<any> | React.ComponentClass<any>) => {
  const withAuthenticationContextHOC = (props: any) => (
    <AuthenticationContext.Consumer>
      {({ principal, login, logout }) => <Component {...props} principal={principal} login={login} logout={logout} />}
    </AuthenticationContext.Consumer>
  );
  withAuthenticationContextHOC.displayName = `WithAuthenticationContext(${getDisplayName(Component)})`;
  return withAuthenticationContextHOC;
};

function getDisplayName(WrappedComponent: React.FunctionComponent | React.ComponentClass) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export { AuthenticationContext, AuthenticationContextProvider, withAuthenticationContext };
