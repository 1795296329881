import React, { useState } from 'react';
import { Button, Modal, Table } from 'antd';
import { useMessageSource } from 'react-message-source';
import { PartCategory } from 'reducers/part-category/types';

interface PartCategoriesTableProps {
  partCategories: Array<PartCategory>;
  onDeletePartCategory: (id: number) => Promise<void>;
  onEditPartCategory: (id: number) => void;
}

export default (props: PartCategoriesTableProps) => {

  const [state, setState] = useState({ modalVisible: false, editingId: -1 });
  const { getMessage } = useMessageSource('page.parts.categories');

  const handleDeleteButtonClick = (id: number | undefined) => {
    if (id) {
      setState({ modalVisible: true, editingId: id });
    }
  };

  const handleEditButtonClick = (id: number | undefined) => {
    if (id) {
      props.onEditPartCategory(id);
    }
  };

  const closeModal = () => {
    setState({ modalVisible: false, editingId: -1 });
  };

  const deletePartCategory = (id: number) => {
    props.onDeletePartCategory(id).then(closeModal);
  };

  const columns = [
    {
      title: getMessage('column.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: getMessage('column.actions'),
      key: 'action',
      render: (_: any, record: PartCategory) => (
        <span>
          <Button type="link" size="small" onClick={(() => handleEditButtonClick(record.id))}>
            {getMessage('action.edit')}
          </Button>
          <Button key={record.id} type="link" size="small" onClick={(() => handleDeleteButtonClick(record.id))}>
            {getMessage('action.delete')}
          </Button>
        </span>
      )
    }
  ];

  return (
    <>
      <Table dataSource={props.partCategories} columns={columns} rowKey="id"/>
      <Modal
        title={getMessage('modal.delete.title')}
        style={{ top: 20 }}
        visible={state.modalVisible}
        onOk={() => deletePartCategory(state.editingId)}
        onCancel={() => closeModal()}
      >
        <p>{getMessage('modal.delete.text')}</p>
      </Modal>
    </>
  );
}
