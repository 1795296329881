import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { RouteComponentProps } from '@reach/router';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { defaultTheme } from 'theme/theme';
import { Card, DatePicker, Row, Statistic, Typography } from 'antd';
import { useMessageSource } from 'react-message-source';
import DashboardService, { ChartData } from 'dashboard/DashboardService';

const now = new Date();

type YearMonth = {
  month: number;
  year: number;
}

const Reports: React.FC<RouteComponentProps> = () => {

  const { Title } = Typography;
  const { MonthPicker } = DatePicker;
  const { getMessage } = useMessageSource('page.reports');
  const [yearMonth, setYearMonth] = useState<YearMonth>({ month: now.getMonth(), year: now.getFullYear()})
  const [chartData, setChartData] = useState<Array<ChartData>>([]);

  useEffect(() => {
    DashboardService.getMonthlyStatistics(yearMonth.month + 1, yearMonth.year).then(chartData => {
      setChartData(chartData);
    });
  }, [yearMonth]);

  const handleDateChange = (newDate: Moment | null) => {
    if (newDate) {
      const month = newDate.month();
      const year = newDate.year();
      setYearMonth({
        month,
        year
      });
    }
  }

  const earningTitle = (
    <Title level={2}>
      {getMessage('title.earnings.month')}
      &nbsp;
      <MonthPicker onChange={handleDateChange} defaultValue={moment([yearMonth.year, yearMonth.month])}  />
    </Title>
  );

  return (
    <>
      <Row>
        <Card title={earningTitle}>
          <Statistic
            suffix="мкд"
            value={chartData.reduce((currentValue, chartData) => currentValue + chartData.grossRevenue, 0)}
          />
        </Card>
      </Row>
      <Row>
        <ResponsiveContainer width="100%" height={450}>
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name" tickFormatter={(name) => getMessage(`page.dashboard.week.${name}`)}/>
            <YAxis/>
            <Tooltip labelFormatter={(name) => getMessage(`page.dashboard.week.${name}`)}/>
            <Legend/>
            <Bar dataKey="grossRevenue" fill={defaultTheme.hint} name={getMessage('page.dashboard.bar.grossRevenue')}/>
            <Bar dataKey="serviceProfit" stackId="a" fill={defaultTheme.secondary}
                 name={getMessage('page.dashboard.bar.serviceProfit')}/>
            <Bar dataKey="partsProfit" stackId="a" fill={defaultTheme.primary} name={getMessage('page.dashboard.bar.partsProfit')}/>
          </BarChart>
        </ResponsiveContainer>
      </Row>
    </>
  );
};

export default Reports;
