import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useMessageSource } from 'react-message-source';
import { Typography } from 'antd';
import { useStateValue } from 'reducers/poorMansRedux';
import ResourcesTable from 'resource/ResourcesTable';
import ResourceForm from 'resource/ResourceForm';
import ResourceService from 'resource/ResourceService';
import {
  resourcesFetchedAction,
  resourceAddedAction,
  resourceUpdatedAction,
  resourceDeletedAction
} from 'reducers/resource/resourceReducer';
import { Resource } from 'reducers/resource/types';

const Resources: React.FC<RouteComponentProps> = () => {

  const [{ resources }, dispatch] = useStateValue();
  const [editingResource, setEditingResource] = useState<Resource>();
  const { getMessage, getMessageWithNamedParams } = useMessageSource('page.resources');

  const handleEditResource = (id: number) => {
    const resourceById = resources.find(resource => resource.id === id);
    setEditingResource(resourceById);
  };

  const handleDeleteResource = (id: number) => {
    return ResourceService.deleteResource(id).then(() => {
      return dispatch(resourceDeletedAction(id));
    });
  };

  const handleSaveResource = (resource: Resource): Promise<void | number> => {
    if (resource.id) {
      return ResourceService.updateResource(resource).then(() => {
        setEditingResource(undefined);
        dispatch(resourceUpdatedAction(resource));
      })
    } else {
      return ResourceService.createResource(resource).then(id=> {
        resource.id = id;
        dispatch(resourceAddedAction(resource));
      })
    }
  };

  useEffect(() => {
    ResourceService.getResources().then(resources => {
      dispatch(resourcesFetchedAction(resources));
    })
  }, [dispatch]);

  const formKey = editingResource ? editingResource.id : 'new';

  const title = editingResource
    ? getMessageWithNamedParams('edit.title', { id: editingResource.id })
    : getMessage('add.title');

  return (
    <>
      <ResourcesTable resources={resources} onDeleteResource={handleDeleteResource} onEditResource={handleEditResource}/>
      <Typography.Title level={2}>{title}</Typography.Title>
      <ResourceForm key={formKey} selectedResource={editingResource} onSaveResource={handleSaveResource}/>
    </>
  );
};

export default Resources;
