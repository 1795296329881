export interface Resource {
  id?: number;
  key: string;
  language: string;
  value: string;
}

export const RESOURCES_FETCHED_ACTION_TYPE = 'RESOURCE@RESOURCES_FETCHED';
export const RESOURCE_ADDED_ACTION_TYPE = 'RESOURCE@RESOURCE_ADDED';
export const RESOURCE_UPDATED_ACTION_TYPE = 'RESOURCE@RESOURCE_UPDATE';
export const RESOURCE_DELETED_ACTION_TYPE = 'RESOURCE@RESOURCE_DELETE';

export interface ResourcesFetchedAction {
  type: typeof RESOURCES_FETCHED_ACTION_TYPE;
  resources: Array<Resource>;
}

export interface ResourceUpdatedAction {
  type: typeof RESOURCE_UPDATED_ACTION_TYPE;
  resource: Resource;
}

export interface ResourceAddedAction {
  type: typeof RESOURCE_ADDED_ACTION_TYPE;
  resource: Resource;
}

export interface ResourceDeletedAction {
  type: typeof RESOURCE_DELETED_ACTION_TYPE;
  id: number;
}

export type ResourceAction = ResourcesFetchedAction
  | ResourceAddedAction
  | ResourceUpdatedAction
  | ResourceDeletedAction;
