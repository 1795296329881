import axios from 'axios';
import { Principal, TOKEN_STORAGE_KEY } from './AuthenticationContext';
import { contextPath } from 'appConstants';

class AuthenticationService {

  tokenInterceptor: number | undefined;

  async executeJwtAuthenticationService(username: string, password: string): Promise<string> {
    const response = await axios.post(`${contextPath}/authenticate`, {
      username,
      password
    });
    return response.data.token;
  }

  async getUserInfo(): Promise<Principal> {
    const response = await axios.get(`${contextPath}/api/user-info`);
    return {
      username: response.data.username,
      roles: response.data.authorities
    };
  }

  registerSuccessfulLoginForJwt(token: string) {
    this.setupAxiosInterceptors(this.createJWTToken(token));
  }

  createJWTToken(token: string) {
    return 'Bearer ' + token;
  }

  isAuthenticated() {
    const storedToken = sessionStorage.getItem(TOKEN_STORAGE_KEY);
    return !!storedToken;
  }

  checkForLoggedInUser() {
    const storedToken = sessionStorage.getItem(TOKEN_STORAGE_KEY);
    if (storedToken) {
      this.registerSuccessfulLoginForJwt(storedToken);
    }
  }

  setupAxiosInterceptors(token: string) {
    this.tokenInterceptor = axios.interceptors.request.use(config => {
      config.headers.authorization = token;
      return config;
    });
  }

  deregisterToken() {
    if (this.tokenInterceptor !== undefined) {
      axios.interceptors.request.eject(this.tokenInterceptor);
      this.tokenInterceptor = undefined;
    }
  }
}

export default new AuthenticationService();
