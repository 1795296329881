import React from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';
import AuthenticationService from 'security/AuthenticationService';
import { withAuthenticationContext, AuthenticationContextShape } from 'security/AuthenticationContext';
import { Button, Form, Icon, Input, notification, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { withMessages } from 'react-message-source';
import { MessageSourceApi } from 'react-message-source/dist/lib/useMessageSource';
import styled from 'styled-components';

interface LoginProps extends RouteComponentProps, AuthenticationContextShape, FormComponentProps, MessageSourceApi {
}

const CenteredForm = styled(Form)`
  display: block;
`;

class Login extends React.Component<LoginProps> {
  constructor(props: LoginProps) {
    super(props);
    this.loginClicked = this.loginClicked.bind(this);
  }

  loginClicked = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { username, password } = values;
        AuthenticationService.executeJwtAuthenticationService(username, password)
          .then(token => {
            AuthenticationService.registerSuccessfulLoginForJwt(token);
            return token;
          })
          .then((token) => {
            AuthenticationService.getUserInfo().then(user => {
              this.props.login({
                username: user.username,
                roles: user.roles
              }, token);
            });
            notification.success({
              message: this.props.getMessage('page.login.success'),
              duration: 2
            });
          });
      }
    });
  };

  render() {
    if (AuthenticationService.isAuthenticated()) {
      return <Redirect to="/" noThrow={true} />
    }
    const { getFieldDecorator } = this.props.form;
    return (
      <CenteredForm onSubmit={this.loginClicked}>
        <Typography.Title>{this.props.getMessage('page.login.title')}</Typography.Title>
        <Form.Item>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: this.props.getMessage('page.login.username.error') }]
          })(<Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                    placeholder={this.props.getMessage('page.login.username.placeholder')}/>)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: this.props.getMessage('page.login.password.error') }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }}/>}
              type="password"
              placeholder={this.props.getMessage('page.login.password.placeholder')}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {this.props.getMessage('page.login.button')}
          </Button>
        </Form.Item>
      </CenteredForm>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'login' })(Login);
export default withAuthenticationContext(withMessages(WrappedNormalLoginForm));
