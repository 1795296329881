import React, { FormEvent } from 'react';
import { Button, DatePicker, Divider, Form, Input, InputNumber, Radio, Select } from 'antd';
import { useMessageSource } from 'react-message-source';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import { formItemLayout, tailFormItemLayout } from 'theme/formLayout';
import DynamicFormArray from 'components/form/DynamicFormArray';
import { Part } from 'reducers/part/types';
import { Intervention } from 'reducers/intervention/types';
import { Device } from 'reducers/device/types';

interface InterventionFormProps extends FormComponentProps {
  selectedIntervention?: Intervention;
  onSaveIntervention: (intervention: Intervention) => Promise<void>;
  onNewClicked: () => void;
  devices: Array<Device>;
  parts: Array<Part>;
}

const InterventionForm: React.FunctionComponent<InterventionFormProps> = (props) => {

  const { getMessage } = useMessageSource('page.interventions.form');
  const { selectedIntervention, onSaveIntervention, onNewClicked, devices, parts, form } = props;

  const { Option } = Select;
  const { TextArea } = Input;
  const { getFieldValue, getFieldDecorator, setFieldsValue, resetFields } = form;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { usedParts_keys, usedParts } = values;
        const interventionData = {
          ...values,
          usedParts: usedParts_keys.map((key: number) => usedParts[key])
        };
        delete interventionData.usedParts_keys;

        if (selectedIntervention) {
          interventionData.id = selectedIntervention.id;
        }

        onSaveIntervention(interventionData).then(() => form.resetFields());
      }
    });
  };

  const devicesOptions = devices.map(device => (
    <Option key={device.id} value={device.id}>{device.deviceName}</Option>
  ));

  const partsOptions = parts.map(part => (
    <Option key={part.id} value={part.id}>{part.name}</Option>
  ));

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Divider orientation="left">{getMessage('title.customer')}</Divider>
      <Form.Item label={getMessage('customerName')} hasFeedback>
        {getFieldDecorator('customerName', {
          initialValue: selectedIntervention && selectedIntervention.customerName
        })(
          <Input placeholder={getMessage('customerName.placeholder')}/>
        )}
      </Form.Item>
      <Form.Item label={getMessage('customerContact')} hasFeedback>
        {getFieldDecorator('customerContact', {
          initialValue: selectedIntervention && selectedIntervention.customerContact
        })(
          <Input placeholder={getMessage('customerContact.placeholder')}/>
        )}
      </Form.Item>
      <Divider orientation="left">{getMessage('title.mobile')}</Divider>
      <Form.Item label={getMessage('mobile')}>
        {getFieldDecorator('deviceId', {
          initialValue: selectedIntervention && selectedIntervention.deviceId,
          rules: [
            {
              required: true,
              message: getMessage('mobile.error')
            }
          ]
        })(
          <Select
            showSearch
            optionFilterProp="children"
            style={{ width: '100%' }}
            placeholder={getMessage('mobile.placeholder')}
          >
            {devicesOptions}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={getMessage('imei')} hasFeedback>
        {getFieldDecorator('imei', {
          initialValue: selectedIntervention && selectedIntervention.imei
        })(
          <Input placeholder={getMessage('imei.placeholder')}/>
        )}
      </Form.Item>
      <Form.Item label={getMessage('unlockCode')} hasFeedback>
        {getFieldDecorator('unlockCode', {
          initialValue: selectedIntervention && selectedIntervention.unlockCode
        })(
          <Input placeholder={getMessage('unlockCode.placeholder')}/>
        )}
      </Form.Item>
      <Divider orientation="left">{getMessage('title.agreed')}</Divider>
      <Form.Item label={getMessage('interventionDate')}>
        {getFieldDecorator('interventionDate', {
          initialValue: (selectedIntervention && selectedIntervention.interventionDate)
            ? moment(selectedIntervention.interventionDate)
            : moment()
        })(<DatePicker format="DD.MM.YYYY"/>)}
      </Form.Item>
      <Form.Item label={getMessage('problemDescription')} hasFeedback>
        {getFieldDecorator('problemDescription', {
          initialValue: selectedIntervention && selectedIntervention.problemDescription
        })(<TextArea rows={5}/>)}
      </Form.Item>
      <Form.Item label={getMessage('note')} hasFeedback>
        {getFieldDecorator('note', {
          initialValue: selectedIntervention && selectedIntervention.note
        })(<TextArea rows={3}/>)}
      </Form.Item>
      <Form.Item label={getMessage('agreedPrice')} hasFeedback>
        {getFieldDecorator('agreedPrice', {
          initialValue: selectedIntervention && selectedIntervention.agreedPrice
        })(<InputNumber min={0} precision={2} decimalSeparator="."/>)}
      </Form.Item>
      <Divider orientation="left">{getMessage('title.details')}</Divider>
      <Form.Item label={getMessage('interventionDetails')} hasFeedback>
        {getFieldDecorator('interventionDetails', {
          initialValue: selectedIntervention && selectedIntervention.interventionDetails
        })(<TextArea rows={5}/>)}
      </Form.Item>
      <Form.Item label={getMessage('usedParts')}>
        <DynamicFormArray
          getFieldValue={getFieldValue}
          getFieldDecorator={getFieldDecorator}
          setFieldsValue={setFieldsValue}
          arrayName="usedParts"
          messagesPrefix="page.interventions.form"
          fields={["partId", "quantity"]}
          initialValue={selectedIntervention && selectedIntervention.usedParts}
          components={
            [
              <Select
                showSearch
                style={{ width: '100%' }}
                optionFilterProp="children"
                placeholder={getMessage('usedParts.part.placeholder')}
              >
                {partsOptions}
              </Select>,
              <InputNumber
                style={{width: '100%' }}
                min={0}
                precision={0}
                placeholder={getMessage('usedParts.quantity.placeholder')}
              />
            ]
          }
        />
      </Form.Item>
      <Form.Item label={getMessage('price')} hasFeedback>
        {getFieldDecorator('price', {
          initialValue: selectedIntervention && selectedIntervention.price
        })(<InputNumber min={0} precision={2} decimalSeparator="."/>)}
      </Form.Item>
      <Form.Item label={getMessage('status')}>
        {getFieldDecorator('status', {
          initialValue: selectedIntervention ? selectedIntervention.status : 'OPEN'
        })(
          <Radio.Group>
            <Radio value="OPEN">{getMessage('status.OPEN')}</Radio>
            <Radio value="FIXED">{getMessage('status.FIXED')}</Radio>
            <Radio value="NOT_FIXABLE">{getMessage('status.NOT_FIXABLE')}</Radio>
            <Radio value="WORKING">{getMessage('status.WORKING')}</Radio>
          </Radio.Group>,
        )}
      </Form.Item>
      <Form.Item label={getMessage('pickUpDate')}>
        {getFieldDecorator('pickUpDate', {
          initialValue: selectedIntervention
            && selectedIntervention.pickUpDate
            && moment(selectedIntervention.pickUpDate)
        })(<DatePicker format="DD.MM.YYYY"/>)}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          {getMessage('button.submit')}
        </Button>
        {
          selectedIntervention
            ? (
                <Button style={{ marginLeft: 8 }} onClick={onNewClicked}>
                  {getMessage('button.new')}
                </Button>
              )
            : (
              <Button type="danger" style={{ marginLeft: 8 }} onClick={() => resetFields()}>
                {getMessage('button.reset')}
              </Button>
              )
        }
      </Form.Item>
    </Form>
  );
};

export default Form.create<InterventionFormProps>({ name: 'interventionForm'})(InterventionForm);
