import React, { FormEvent } from 'react';

import { Form, Input, Button, Select } from 'antd';
import { useMessageSource } from 'react-message-source';
import { FormComponentProps } from 'antd/lib/form';
import { formItemLayout, tailFormItemLayout } from 'theme/formLayout';
import { Role, User } from 'reducers/user/types';
import PasswordFields from 'user/PasswordFields';

interface UserFormProps extends FormComponentProps {
  selectedUser?: User;
  onNewClicked: () => void;
  roles: Array<Role>;
  onSaveUser: (user: User) => Promise<void | number>;
}

const UserForm: React.FunctionComponent<UserFormProps> = (props) => {

  const { getMessage } = useMessageSource('page.users.form');

  const { selectedUser, roles, onNewClicked } = props;
  const { getFieldDecorator, resetFields } = props.form;
  const { Option } = Select;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const userData: User = {
          ...values
        };

        if (selectedUser) {
          userData.id = selectedUser.id;
        }

        props.onSaveUser(userData).then(() => props.form.resetFields());
      }
    });
  };

  const rolesOptions = roles.map(role => (
    <Option key={role.id} value={role.name}>{role.name}</Option>
  ));

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label={getMessage('firstName')} hasFeedback>
        {getFieldDecorator('firstName', {
          initialValue: selectedUser && selectedUser.firstName
        })(<Input/>)}
      </Form.Item>
      <Form.Item label={getMessage('lastName')} hasFeedback>
        {getFieldDecorator('lastName', {
          initialValue: selectedUser && selectedUser.lastName
        })(<Input/>)}
      </Form.Item>
      <Form.Item label={getMessage('username')} hasFeedback>
        {getFieldDecorator('username', {
          initialValue: selectedUser && selectedUser.username,
          rules: [
            {
              required: true,
              message: getMessage('email.error')
            }
          ]
        })(<Input/>)}
      </Form.Item>
      <Form.Item label={getMessage('email')} hasFeedback>
        {getFieldDecorator('email', {
          initialValue: selectedUser && selectedUser.email,
          rules: [
            {
              required: true,
              message: getMessage('email.error')
            },
            {
              type: 'email',
              message: getMessage('email.error')
            },
          ]
        })(<Input/>)}
      </Form.Item>
      {
        !selectedUser && (
          <PasswordFields form={props.form}/>
        )
      }
      <Form.Item
        label={getMessage('roles')}
        required={false}
        hasFeedback
      >
        {getFieldDecorator('roles',{
          initialValue: selectedUser && selectedUser.roles
        })(
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={getMessage('roles.placeholder')}
          >
            {rolesOptions}
          </Select>
        )}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          {getMessage('button.submit')}
        </Button>
        {
          selectedUser
            ? (
              <Button style={{ marginLeft: 8 }} onClick={onNewClicked}>
                {getMessage('button.new')}
              </Button>
            )
            : (
              <Button type="danger" style={{ marginLeft: 8 }} onClick={() => resetFields()}>
                {getMessage('button.reset')}
              </Button>
            )
        }
      </Form.Item>
    </Form>
  );
};

export default Form.create<UserFormProps>({ name: 'userForm' })(UserForm);
