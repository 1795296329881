import React from 'react';
import styled, { ThemeProps } from 'styled-components';
import { useMessageSource } from 'react-message-source';
import { Theme } from 'theme/theme';

const StyledFooter = styled.footer`
  color: ${(props: ThemeProps<Theme>) => props.theme.hint};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Footer: React.FC = () => {

  const { getMessage } = useMessageSource();

  return (
    <StyledFooter>
      <p>{getMessage('copyright')}</p>
    </StyledFooter>
  )
};

export default Footer;
