import React, { useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import styled, { ThemeProvider } from 'styled-components';
import { Provider as MessageSourceProvider } from 'react-message-source';
import { Router } from '@reach/router';
import translations from 'i18n/translations';
import { defaultTheme } from 'theme/theme';
import Header from 'components/Header';
import Footer from 'components/Footer';
import 'fonts/fonts.css';
import 'antd/dist/antd.css';
import { allPages } from 'pages/routes';
import SideBar from 'components/SideNavigation';
import { PrivateRoute } from 'security/PrivateRoute';
import NotFound from 'pages/NotFound';
import { AuthenticationContextProvider } from 'security/AuthenticationContext';
import { useStateValue } from 'reducers/poorMansRedux';
import BootstrapService from 'bootstrap/bootstrapService';
import { bootstrapApplication } from 'reducers/global/globalDataReducer';
import { notification } from 'antd';
import Bootstrapper from 'bootstrap/Bootstrapper';

const MainContent = styled.article`
  display: grid;
  grid-template-columns: 280px auto;
`;

const StyledRouter = styled(Router)`
  padding-right: 10px;
`;

type ApiError = AxiosError & {
  config: {
    globalErrorHandle: boolean;
  }
}

const App: React.FC = () => {
  const [{ global }, dispatch] = useStateValue();
  const { language } = global;

  useEffect(() => {
    document.title = translations[language]['app.name'];
    BootstrapService.getState().then(state => {
      dispatch(bootstrapApplication(state));
    });
    const globalErrorHandler = (error: ApiError) => {
      // check for errorHandle config
      if( error.config.hasOwnProperty('globalErrorHandle') && !error.config.globalErrorHandle ) {
        return Promise.reject(error);
      }

      // if has response show the error
      if (error.response) {
        const message = error.response.data.message || error.response.data || error.response.statusText;
        notification.error( { message: translations[language][`${message}`] || message });
      }

      return Promise.reject(error);
    };
    const interceptorId = axios.interceptors.response.use(
      response => response,
      globalErrorHandler
    );

    return () => {
      axios.interceptors.response.eject(interceptorId);
    }
  }, [language, dispatch]);

  return (
    <AuthenticationContextProvider>
      <MessageSourceProvider value={translations[language]}>
        <ThemeProvider theme={defaultTheme}>
          <Bootstrapper>
            <Header />
            <MainContent>
              <SideBar />
              <StyledRouter primary={false}>
                {allPages
                  .filter(route => route.public)
                  .map(route => (
                    <route.component {...route} key={route.path} />
                  ))}
                {allPages
                  .filter(route => !route.public)
                  .map(route => (
                    <PrivateRoute route={route} {...route} key={route.path} />
                  ))}
                <NotFound default={true} />
              </StyledRouter>
            </MainContent>
            <Footer />
          </Bootstrapper>
        </ThemeProvider>
      </MessageSourceProvider>
    </AuthenticationContextProvider>
  );
};

export default App;
