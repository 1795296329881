import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { useMessageSource } from 'react-message-source';
import { FormComponentProps } from 'antd/es/form';

export default (props: FormComponentProps) => {

  const { getMessage } = useMessageSource('component.passwords');
  const [confirmDirty, setConfirmDirty] = useState(false);
  const { getFieldDecorator } = props.form;

  const compareToFirstPassword = (rule: any, value: string, callback: any) => {
    const { form } = props;
    if (value && value !== form.getFieldValue('password')) {
      callback(getMessage('error.password.mismatch'));
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule: any, value: string, callback: any) => {
    const { form } = props;
    if (value) {
      if (value.length < 8) {
        callback(getMessage('error.password.length'))
      }
      if (confirmDirty) {
        form.validateFields(['matchingPassword'], { force: true });
      }
    }
    callback();
  };

  const handleMatchingPasswordBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  return (
    <>
      <Form.Item label={getMessage('password')} hasFeedback>
        {getFieldDecorator('password', {
          rules: [
            {
              required: true,
              message: getMessage('password.error')
            },
            {
              validator: validateToNextPassword
            }
          ]
        })(<Input type="password"/>)}
      </Form.Item>
      <Form.Item label={getMessage('matchingPassword')} hasFeedback>
        {getFieldDecorator('matchingPassword', {
          rules: [
            {
              required: true,
              message: getMessage('matchingPassword.error')
            },
            {
              validator: compareToFirstPassword
            }
          ]
        })(<Input type="password" onBlur={handleMatchingPasswordBlur}/>)}
      </Form.Item>
    </>
  )
};
