import React, { FormEvent } from 'react';

import { Button, Form, Input } from 'antd';
import { useMessageSource } from 'react-message-source';
import { FormComponentProps } from 'antd/lib/form';
import { formItemLayout, tailFormItemLayout } from 'theme/formLayout';
import { PartCategory } from 'reducers/part-category/types';

interface PartCategoryFormProps extends FormComponentProps {
  selectedPartCategory?: PartCategory;
  onSavePartCategory: (partCategory: PartCategory) => Promise<void | number>;
}

const PartCategoryForm: React.FunctionComponent<PartCategoryFormProps> = (props) => {

  const { getMessage } = useMessageSource('page.parts.categories.form');
  const { selectedPartCategory, form } = props;
  const { getFieldDecorator } = form;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const partCategoryData: PartCategory = {
          ...values,
        };

        if (selectedPartCategory) {
          partCategoryData.id = selectedPartCategory.id;
        }

        props.onSavePartCategory(partCategoryData).then(() => form.resetFields());
      }
    });
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label={getMessage('name')} hasFeedback>
        {getFieldDecorator('name', {
          initialValue: selectedPartCategory && selectedPartCategory.name,
          rules: [
            {
              required: true,
              message: getMessage('name.error')
            }
          ]
        })(<Input/>)}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          {getMessage('button.submit')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create<PartCategoryFormProps>({ name: 'partCategoryForm' })(PartCategoryForm);
