import { BootstrapAction } from 'reducers/types';

export interface PartCategory {
  id?: number;
  name: string;
}

export const PART_CATEGORIES_FETCHED_ACTION_TYPE = 'PART@PART_CATEGORIES_FETCHED';
export const PART_CATEGORY_ADDED_ACTION_TYPE = 'PART@PART_CATEGORY_ADDED';
export const PART_CATEGORY_UPDATED_ACTION_TYPE = 'PART@PART_CATEGORY_UPDATE';
export const PART_CATEGORY_DELETED_ACTION_TYPE = 'PART@PART_CATEGORY_DELETE';

export interface PartCategoriesFetchedAction {
  type: typeof PART_CATEGORIES_FETCHED_ACTION_TYPE;
  partCategories: Array<PartCategory>;
}

export interface PartCategoryUpdatedAction {
  type: typeof PART_CATEGORY_UPDATED_ACTION_TYPE;
  partCategory: PartCategory;
}

export interface PartCategoryAddedAction {
  type: typeof PART_CATEGORY_ADDED_ACTION_TYPE;
  partCategory: PartCategory;
}

export interface PartCategoryDeletedAction {
  type: typeof PART_CATEGORY_DELETED_ACTION_TYPE;
  id: number;
}

export type PartCategoryAction = BootstrapAction
  | PartCategoriesFetchedAction
  | PartCategoryAddedAction
  | PartCategoryUpdatedAction
  | PartCategoryDeletedAction;
