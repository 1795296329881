import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useMessageSource } from 'react-message-source';
import DevicesTable from 'device/DevicesTable';
import { Typography } from 'antd';
import DeviceForm from 'device/DeviceForm';
import { useStateValue } from 'reducers/poorMansRedux';
import { Device } from 'reducers/device/types';
import DeviceService from 'device/DeviceService';
import {
  deviceAddedAction,
  deviceDeletedAction,
  devicesFetchedAction,
  deviceUpdatedAction
} from 'reducers/device/deviceReducer';

const Devices: React.FC<RouteComponentProps> = () => {

  const [{ devices }, dispatch] = useStateValue();
  const [editingDevice, setEditingDevice] = useState<Device>();
  const { getMessage, getMessageWithNamedParams } = useMessageSource('page.devices');

  const handleEditDevice = (id: number) => {
    const deviceById = devices.find(device => device.id === id);
    setEditingDevice(deviceById);
  };

  const handleDeleteDevice = (id: number) => {
    return DeviceService.deleteDevice(id).then(() => {
      return dispatch(deviceDeletedAction(id));
    });
  };

  const handleSaveDevice = (device: Device): Promise<void | number> => {
    if (device.id) {
      return DeviceService.updateDevice(device).then(() => {
        setEditingDevice(undefined);
        dispatch(deviceUpdatedAction(device));
      })
    } else {
      return DeviceService.createDevice(device).then(id=> {
        device.id = id;
        dispatch(deviceAddedAction(device));
      })
    }
  };

  useEffect(() => {
    DeviceService.getDevices().then(devices => {
      dispatch(devicesFetchedAction(devices));
    })
  }, [dispatch]);

  const formKey = editingDevice ? editingDevice.id : 'new';

  const title = editingDevice
    ? getMessageWithNamedParams('edit.title', { id: editingDevice.id })
    : getMessage('add.title');

  return (
    <>
      <DevicesTable devices={devices} onDeleteDevice={handleDeleteDevice} onEditDevice={handleEditDevice}/>
      <Typography.Title level={2}>{title}</Typography.Title>
      <DeviceForm key={formKey} selectedDevice={editingDevice} onSaveDevice={handleSaveDevice}/>
    </>
  );
};

export default Devices;
