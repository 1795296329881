import axios from 'axios';
import { contextPath } from 'appConstants';
import { Device } from 'reducers/device/types';

class DeviceService {
  async getDevices(): Promise<Array<Device>> {
    return await axios.get(`${contextPath}/api/devices`).then(response => response.data);
  }

  async createDevice(device: Device): Promise<number> {
    return await axios.post(`${contextPath}/api/devices`, { ...device }).then(response => response.data);
  }

  async updateDevice(device: Device): Promise<void> {
    return await axios.put(`${contextPath}/api/devices`, { ...device }).then(response => response.data);
  }

  async deleteDevice(id: number): Promise<void> {
    return await axios.delete(`${contextPath}/api/devices/${id}`).then(response => response.data);
  }
}

export default new DeviceService();
