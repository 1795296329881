import { BootstrapAction } from 'reducers/types';

export interface Customer {
  id?: number;
  name: string;
  address: string;
  taxNumber: string;
  contacts: Array<string>;
}

export const CUSTOMERS_FETCHED_ACTION_TYPE = 'CUSTOMER@CUSTOMERS_FETCHED';
export const CUSTOMER_ADDED_ACTION_TYPE = 'CUSTOMER@CUSTOMER_ADDED';
export const CUSTOMER_UPDATED_ACTION_TYPE = 'CUSTOMER@CUSTOMER_UPDATE';
export const CUSTOMER_DELETED_ACTION_TYPE = 'CUSTOMER@CUSTOMER_DELETE';

export interface CustomersFetchedAction {
  type: typeof CUSTOMERS_FETCHED_ACTION_TYPE;
  customers: Array<Customer>;
}

export interface CustomerUpdatedAction {
  type: typeof CUSTOMER_UPDATED_ACTION_TYPE;
  customer: Customer;
}

export interface CustomerAddedAction {
  type: typeof CUSTOMER_ADDED_ACTION_TYPE;
  customer: Customer;
}

export interface CustomerDeletedAction {
  type: typeof CUSTOMER_DELETED_ACTION_TYPE;
  id: number;
}

export type CustomerAction = BootstrapAction
  | CustomersFetchedAction
  | CustomerAddedAction
  | CustomerUpdatedAction
  | CustomerDeletedAction;
