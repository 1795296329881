import { Device } from 'reducers/device/types';
import { BootstrapAction } from 'reducers/types';

export interface Part {
  id?: number;
  name: string;
  partCategoryId: number;
  category: string;
  purchasePrice: number;
  onStock: number;
  compatibleWith?: Array<Device>;
}

export const PARTS_FETCHED_ACTION_TYPE = 'PART@PARTS_FETCHED';
export const PART_ADDED_ACTION_TYPE = 'PART@PART_ADDED';
export const PART_UPDATED_ACTION_TYPE = 'PART@PART_UPDATE';
export const PART_DELETED_ACTION_TYPE = 'PART@PART_DELETE';

export interface PartsFetchedAction {
  type: typeof PARTS_FETCHED_ACTION_TYPE;
  parts: Array<Part>;
}

export interface PartUpdatedAction {
  type: typeof PART_UPDATED_ACTION_TYPE;
  part: Part;
}

export interface PartAddedAction {
  type: typeof PART_ADDED_ACTION_TYPE;
  part: Part;
}

export interface PartDeletedAction {
  type: typeof PART_DELETED_ACTION_TYPE;
  id: number;
}

export type PartAction = BootstrapAction
  | PartsFetchedAction
  | PartAddedAction
  | PartUpdatedAction
  | PartDeletedAction;
