import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import AuthenticationService from 'security/AuthenticationService';
import { StateProvider } from 'reducers/poorMansRedux';
import { initialState } from 'reducers/initialAppState';
import { rootReducer } from 'reducers/rootReducer';

AuthenticationService.checkForLoggedInUser();

ReactDOM.render((
  <StateProvider initialState={initialState} reducer={rootReducer}>
    <App />
  </StateProvider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
