import {
  PART_CATEGORIES_FETCHED_ACTION_TYPE,
  PART_CATEGORY_ADDED_ACTION_TYPE,
  PART_CATEGORY_DELETED_ACTION_TYPE,
  PART_CATEGORY_UPDATED_ACTION_TYPE,
  PartCategoriesFetchedAction,
  PartCategory,
  PartCategoryAction,
  PartCategoryAddedAction,
  PartCategoryDeletedAction,
  PartCategoryUpdatedAction
} from './types';
import { BOOTSTRAP_ACTION_TYPE } from 'reducers/types';

export const partCategoriesFetchedAction = (partCategories: Array<PartCategory>): PartCategoriesFetchedAction => ({
  type: PART_CATEGORIES_FETCHED_ACTION_TYPE,
  partCategories
});

export const partCategoryAddedAction = (partCategory: PartCategory): PartCategoryAddedAction => ({
  type: PART_CATEGORY_ADDED_ACTION_TYPE,
  partCategory
});

export const partCategoryUpdatedAction = (partCategory: PartCategory): PartCategoryUpdatedAction => ({
  type: PART_CATEGORY_UPDATED_ACTION_TYPE,
  partCategory
});

export const partCategoryDeletedAction = (id: number): PartCategoryDeletedAction => ({
  type: PART_CATEGORY_DELETED_ACTION_TYPE,
  id
});

const partCategoryReducer = (state: Array<PartCategory>, action: PartCategoryAction) => {
  switch (action.type) {
    case BOOTSTRAP_ACTION_TYPE:
      return action.state.partCategories;
    case PART_CATEGORIES_FETCHED_ACTION_TYPE:
      return action.partCategories;
    case PART_CATEGORY_ADDED_ACTION_TYPE:
      return [...state, action.partCategory];
    case PART_CATEGORY_UPDATED_ACTION_TYPE:
      return state.map(partCategory => {
        if (partCategory.id === action.partCategory.id) {
          return action.partCategory;
        }
        return partCategory;
      });
    case PART_CATEGORY_DELETED_ACTION_TYPE:
      return state.filter(partCategory => partCategory.id !== action.id);
    default:
      return state;
  }
};

export default partCategoryReducer;
