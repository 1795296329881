import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Card, Col, Icon, Row, Statistic, Typography } from 'antd';
import { useMessageSource } from 'react-message-source';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { defaultTheme } from 'theme/theme';
import DashboardService, { Statistics } from 'dashboard/DashboardService';

const Dashboard: React.FC<RouteComponentProps> = () => {

  const { getMessage } = useMessageSource('page.dashboard');
  const { Title } = Typography;

  const [ statisticsData, setStatisticsData ] = useState<Statistics>({
    services: 0,
    servicesToday: 0,
    servicesPending: 0,
    customers: 0,
    mobiles: 0,
    users: 0,
    monthEarnings: 0,
    yearEarnings: 0,
    monthChartData: [],
    yearChartData: []
  });

  useEffect(() => {
    DashboardService.getStatistics().then(statistics => {
      setStatisticsData(statistics);
    });
  }, []);

  return (
    <div>
      <Row gutter={8}>
        <Col span={8}>
          <Card title={getMessage('statistic.services.today.title')}>
            <Statistic value={statisticsData.servicesToday}/>
          </Card>
        </Col>
        <Col span={8}>
          <Card title={getMessage('statistic.services.pending.title')}>
            <Statistic value={statisticsData.servicesPending}/>
          </Card>
        </Col>
        <Col span={8}>
          <Card title={getMessage('statistic.services.total.title')}>
            <Statistic value={statisticsData.services}/>
          </Card>
        </Col>
      </Row>
      <Row gutter={8} style={{marginTop: "8px"}}>
        <Col span={8}>
          <Card title={getMessage('statistic.mobiles.title')}>
            <Statistic value={statisticsData.mobiles} prefix={<Icon type="mobile" />}/>
          </Card>
        </Col>
        <Col span={8}>
          <Card title={getMessage('statistic.customers.title')}>
            <Statistic value={statisticsData.customers} prefix={<Icon type="customer-service" />}/>
          </Card>
        </Col>
        <Col span={8}>
          <Card title={getMessage('statistic.users.title')}>
            <Statistic value={statisticsData.users} prefix={<Icon type="user" />}/>
          </Card>
        </Col>
      </Row>
      <Row gutter={8} style={{marginTop: "8px"}}>
        <Col span={24}>
          <Card title={getMessage('statistic.earnings.title')}>
            <Statistic
              prefix={<Icon type="bank" />}
              value={statisticsData.monthEarnings}
              suffix={getMessage('statistic.month')}
            />
            <Statistic
              prefix={<Icon type="bank" />}
              value={statisticsData.yearEarnings}
              suffix={getMessage('statistic.year')}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={2}>{getMessage('title.earnings.month')}</Title>
          <ResponsiveContainer width="100%" height={450}>
            <BarChart data={statisticsData.monthChartData}>
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="name" tickFormatter={(name) => getMessage(`page.dashboard.week.${name}`)}/>
              <YAxis />
              <Tooltip labelFormatter={(name) => getMessage(`page.dashboard.week.${name}`)} />
              <Legend />
              <Bar dataKey="grossRevenue" fill={defaultTheme.hint} name={getMessage('bar.grossRevenue')} />
              <Bar dataKey="serviceProfit" stackId="a" fill={defaultTheme.secondary} name={getMessage('bar.serviceProfit')} />
              <Bar dataKey="partsProfit" stackId="a" fill={defaultTheme.primary} name={getMessage('bar.partsProfit')} />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={2}>{getMessage('title.earnings.year')}</Title>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;