import { BootstrapAction } from 'reducers/types';

type InterventionStatus = 'OPEN' | 'FIXED' | 'NOT_FIXABLE' | 'WORKING';

export interface Intervention {
  id: number;
  customerId: number;
  customerName: string;
  customerContact: string;
  status: InterventionStatus;
  deviceId: number;
  mobileModel: string;
  imei: string;
  unlockCode: string;
  interventionNumber: string;
  interventionDate: Date;
  pickUpDate: Date;
  problemDescription: string;
  note: string;
  agreedPrice: number;
  price: number;
  interventionDetails: string;
  usedParts: Array<PartIntervention>
}

export interface PartIntervention {
  quantity: number;
  partName: string;
  partPurchasePrice: number;
  partId: number;
}

export const INTERVENTIONS_FETCHED_ACTION_TYPE = 'INTERVENTION@INTERVENTIONS_FETCHED';
export const INTERVENTION_ADDED_ACTION_TYPE = 'INTERVENTION@INTERVENTION_ADDED';
export const INTERVENTION_UPDATED_ACTION_TYPE = 'INTERVENTION@INTERVENTION_UPDATE';
export const INTERVENTION_DELETED_ACTION_TYPE = 'INTERVENTION@INTERVENTION_DELETE';

export interface InterventionsFetchedAction {
  type: typeof INTERVENTIONS_FETCHED_ACTION_TYPE;
  interventions: Array<Intervention>;
}

export interface InterventionUpdatedAction {
  type: typeof INTERVENTION_UPDATED_ACTION_TYPE;
  intervention: Intervention;
}

export interface InterventionAddedAction {
  type: typeof INTERVENTION_ADDED_ACTION_TYPE;
  intervention: Intervention;
}

export interface InterventionDeletedAction {
  type: typeof INTERVENTION_DELETED_ACTION_TYPE;
  id: number;
}

export type InterventionAction = BootstrapAction
  | InterventionsFetchedAction
  | InterventionAddedAction
  | InterventionUpdatedAction
  | InterventionDeletedAction;
