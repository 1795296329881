import React, { useState } from 'react';
import { Button, Modal, Table, Tag } from 'antd';
import { useMessageSource } from 'react-message-source';
import { Device } from 'reducers/device/types';
import { Part } from 'reducers/part/types';

interface PartsTableProps {
  parts: Array<Part>;
  onDeletePart: (id: number) => Promise<void>;
  onEditPart: (id: number) => void;
}

export default (props: PartsTableProps) => {

  const [state, setState] = useState({ modalVisible: false, editingId: -1 });
  const { getMessage } = useMessageSource('page.parts');

  const handleDeleteButtonClick = (id: number | undefined) => {
    if (id) {
      setState({ modalVisible: true, editingId: id });
    }
  };

  const handleEditButtonClick = (id: number | undefined) => {
    if (id) {
      props.onEditPart(id);
    }
  };

  const closeModal = () => {
    setState({ modalVisible: false, editingId: -1 });
  };

  const deletePart = (id: number) => {
    props.onDeletePart(id).then(closeModal);
  };

  const columns = [
    {
      title: getMessage('column.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: getMessage('column.purchasePrice'),
      dataIndex: 'purchasePrice',
      key: 'purchasePrice'
    },
    {
      title: getMessage('column.onStock'),
      dataIndex: 'onStock',
      key: 'onStock'
    },
    {
      title: getMessage('column.category'),
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: getMessage('column.compatibleWith'),
      dataIndex: 'compatibleWith',
      key: 'compatibleWith',
      render: (compatibleWith: Array<Device>) => (
        <span>
          {compatibleWith.map(device => {
            return (
              <Tag color={'red'} key={device.id}>
                {device.deviceName}
              </Tag>
            );
          })}
        </span>
      )
    },
    {
      title: getMessage('column.actions'),
      key: 'action',
      render: (_: any, record: Part) => (
        <span>
          <Button type="link" size="small" onClick={(() => handleEditButtonClick(record.id))}>
            {getMessage('action.edit')}
          </Button>
          <Button key={record.id} type="link" size="small" onClick={(() => handleDeleteButtonClick(record.id))}>
            {getMessage('action.delete')}
          </Button>
        </span>
      )
    }
  ];

  return (
    <>
      <Table dataSource={props.parts} columns={columns} rowKey="id"/>
      <Modal
        title={getMessage('modal.delete.title')}
        style={{ top: 20 }}
        visible={state.modalVisible}
        onOk={() => deletePart(state.editingId)}
        onCancel={() => closeModal()}
      >
        <p>{getMessage('modal.delete.text')}</p>
      </Modal>
    </>
  );
}
