import React, { FormEvent } from 'react';

import { Button, Form, Input, InputNumber, Select } from 'antd';
import { useMessageSource } from 'react-message-source';
import { FormComponentProps } from 'antd/lib/form';
import { formItemLayout, tailFormItemLayout } from 'theme/formLayout';
import { Device } from 'reducers/device/types';
import { Part } from 'reducers/part/types';
import { PartCategory } from 'reducers/part-category/types';

interface PartFormProps extends FormComponentProps {
  devices: Array<Device>;
  partCategories: Array<PartCategory>;
  selectedPart?: Part;
  onSavePart: (part: Part) => Promise<void | number>;
}

const DeviceForm: React.FunctionComponent<PartFormProps> = (props) => {

  const { getMessage } = useMessageSource('page.parts.form');
  const { Option } = Select;
  const { selectedPart, devices, form, partCategories } = props;
  const { getFieldDecorator } = form;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const partData: Part = {
          ...values,
          compatibleWith: devices.filter(device => values.compatibleWith.includes(device.id))
        };

        if (selectedPart) {
          partData.id = selectedPart.id;
        }

        props.onSavePart(partData).then(() => form.resetFields());
      }
    });
  };

  const devicesOptions = devices.map(device => (
    <Option key={device.id} value={device.id}>{device.deviceName}</Option>
  ));

  const selectedPartDevicesIds = selectedPart && selectedPart.compatibleWith
    ? selectedPart.compatibleWith.map(device => device.id)
    : [];

  const partCategoriesOptions = partCategories.map(partCategory => (
    <Option key={partCategory.id} value={partCategory.id}>{partCategory.name}</Option>
  ));

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label={getMessage('name')} hasFeedback>
        {getFieldDecorator('name', {
          initialValue: selectedPart && selectedPart.name,
          rules: [
            {
              required: true,
              message: getMessage('name.error')
            }
          ]
        })(<Input/>)}
      </Form.Item>
      <Form.Item label={getMessage('partCategory')} hasFeedback>
        {getFieldDecorator('partCategoryId', {
          initialValue: selectedPart && selectedPart.partCategoryId,
        })(
          <Select
            optionFilterProp="children"
            style={{ width: '100%' }}
            placeholder={getMessage('partCategory.placeholder')}
          >
            {partCategoriesOptions}
          </Select>)}
      </Form.Item>
      <Form.Item label={getMessage('purchasePrice')} hasFeedback>
        {getFieldDecorator('purchasePrice', {
          initialValue: selectedPart && selectedPart.purchasePrice,
          rules: [
            {
              required: true,
              message: getMessage('purchasePrice.error')
            }
          ]
        })(<InputNumber min={0} precision={2} decimalSeparator="."/>)}
      </Form.Item>
      <Form.Item label={getMessage('onStock')} hasFeedback>
        {getFieldDecorator('onStock', {
          initialValue: selectedPart && selectedPart.onStock,
          rules: [
            {
              required: true,
              message: getMessage('onStock.error')
            }
          ]
        })(<InputNumber />)}
      </Form.Item>
      <Form.Item label={getMessage('compatibleWith')} hasFeedback>
        {getFieldDecorator('compatibleWith', {
          initialValue: selectedPartDevicesIds,
        })(
          <Select
            mode="multiple"
            optionFilterProp="children"
            style={{ width: '100%' }}
            placeholder={getMessage('compatibleWith.placeholder')}
          >
            {devicesOptions}
          </Select>)}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          {getMessage('button.submit')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create<PartFormProps>({ name: 'partForm' })(DeviceForm);
