import axios from 'axios';
import { contextPath } from 'appConstants';
import { Part } from 'reducers/part/types';
import { PartCategory } from 'reducers/part-category/types';

class PartService {
  async getParts(): Promise<Array<Part>> {
    return await axios.get(`${contextPath}/api/parts`).then(response => response.data);
  }

  async createPart(part: Part): Promise<Part> {
    return await axios.post(`${contextPath}/api/parts`, { ...part }).then(response => response.data);
  }

  async updatePart(part: Part): Promise<Part> {
    return await axios.put(`${contextPath}/api/parts`, { ...part }).then(response => response.data);
  }

  async deletePart(id: number): Promise<void> {
    return await axios.delete(`${contextPath}/api/parts/${id}`).then(response => response.data);
  }

  async getPartCategories(): Promise<Array<PartCategory>> {
    return await axios.get(`${contextPath}/api/part-categories`).then(response => response.data);
  }

  async createPartCategory(partCategory: PartCategory): Promise<number> {
    return await axios.post(`${contextPath}/api/part-categories`, { ...partCategory }).then(response => response.data);
  }

  async updatePartCategory(partCategory: PartCategory): Promise<void> {
    return await axios.put(`${contextPath}/api/part-categories`, { ...partCategory }).then(response => response.data);
  }

  async deletePartCategory(id: number): Promise<void> {
    return await axios.delete(`${contextPath}/api/part-categories/${id}`).then(response => response.data);
  }

}

export default new PartService();
