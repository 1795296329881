import React, { useState } from 'react';
import dateFormat from 'dateformat';
import InvoiceService  from './InvoiceService';
import { Button, Modal, Table, Tag } from 'antd';
import { useMessageSource } from 'react-message-source';
import { RouteComponentProps } from '@reach/router';
import { Invoice } from 'reducers/invoice/types';
import { useStateValue } from 'reducers/poorMansRedux';

interface InvoicesTableProps extends RouteComponentProps {
  invoices: Array<Invoice>;
  onDeleteInvoice: (id: number) => Promise<void>;
  onEditInvoice: (id: number) => void;
}

export default ({navigate, invoices, onDeleteInvoice, onEditInvoice}: InvoicesTableProps) => {

  const [ {global} ] = useStateValue();
  const [state, setState] = useState({ modalVisible: false, editingId: -1 });
  const { getMessage } = useMessageSource('page.invoices');

  const handleDeleteButtonClick = (id: number | undefined) => {
    if (id) {
      setState({ modalVisible: true, editingId: id });
    }
  };

  const handleEditButtonClick = (id: number | undefined) => {
    if (id) {
      onEditInvoice(id);
    }
  };

  const handlePrintButtonClick = (id: number | undefined) => {
    if (id) {
      InvoiceService.printInvoice(id, global.language).then(blob => {
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  const closeModal = () => {
    setState({ modalVisible: false, editingId: -1 });
  };

  const deleteInvoice = (id: number) => {
    if (id) {
      onDeleteInvoice(id).then(closeModal);
    }
  };

  const columns = [
    {
      title: getMessage('column.invoiceNumber'),
      dataIndex: 'id',
      key: 'id',
      render: (_: any, record: Invoice) => (
        <Button type="link" onClick={() => navigate && navigate(record.id.toString())}>
          {record.id}
        </Button>
      )
    },
    {
      title: getMessage('column.customerName'),
      dataIndex: 'customerName',
      key: 'customerName'
    },
    {
      title: getMessage('column.customerContact'),
      dataIndex: 'customerContact',
      key: 'customerContact'
    },
    {
      title: getMessage('column.customerAddress'),
      dataIndex: 'customerAddress',
      key: 'customerAddress'
    },
    {
      title: getMessage('column.dueDate'),
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (_:any, record: Invoice) => (
        <span>{dateFormat(record.dueDate, 'dd.mm.yyyy')}</span>
      )
    },
    {
      title: getMessage('column.paid'),
      dataIndex: 'paid',
      key: 'paid',
      render: (_: any, record: Invoice) => (
        <Tag color={record.paid ? 'green' : 'red'} key={record.id}>
          {record.paid ? getMessage("status.PAID") : getMessage("status.NOT_PAID")}
        </Tag>
      )
    },
    {
      title: getMessage('column.actions'),
      key: 'action',
      render: (_: any, record: Invoice) => (
        <span key={record.id}>
          <Button type="link" size="small" onClick={(() => handleEditButtonClick(record.id))}>
            {getMessage('action.edit')}
          </Button>
          <Button type="link" size="small" onClick={() => handleDeleteButtonClick(record.id)}>
            {getMessage('action.delete')}
          </Button>
          <Button type="link" size="small" onClick={() => handlePrintButtonClick(record.id)}>
            {getMessage('action.print')}
          </Button>
        </span>
      )
    }
  ];

  return (
    <>
      <Table dataSource={invoices} columns={columns} rowKey="id"/>
      <Modal
        title={getMessage('modal.delete.title')}
        style={{ top: 20 }}
        visible={state.modalVisible}
        onOk={() => deleteInvoice(state.editingId)}
        onCancel={() => closeModal()}
      >
        <p>{getMessage('modal.delete.text')}</p>
      </Modal>
    </>
  );
}
