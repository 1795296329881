import React from 'react';
import styled, { ThemeProps } from 'styled-components';
import { Theme } from 'theme/theme';
import { Popover, Button, Row, Col } from 'antd';
import Logo from './Logo';
import { withAuthenticationContext, AuthenticationContextShape } from 'security/AuthenticationContext';
import { useMessageSource } from 'react-message-source';
import LanguageSwitcher from 'i18n/switcher/LanguageSwitcher';

const StyledHeader = styled.header`
  background-color: ${(props: ThemeProps<Theme>) => props.theme.secondary};
  color: ${(props: ThemeProps<Theme>) => props.theme.background};
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserDetails = styled.div`
  flex: 0 0 auto; /* Forces side columns to stay same width */
  width: 150px;
`;

const Header: React.FC<AuthenticationContextShape> = props => {
  const { getMessage } = useMessageSource();
  return (
    <StyledHeader>
      <Logo />
      <UserDetails>
        <Row>
          {props.principal && (
          <Col span={12}>
            <Popover
              content={
                <Button type="link" onClick={props.logout}>
                  {getMessage('user.logout')}
                </Button>
              }
              title=""
              trigger="hover"
            >
              {props.principal.username}
            </Popover>
          </Col>
          )}
          <Col span={6}>
            <LanguageSwitcher />
          </Col>
        </Row>
      </UserDetails>
    </StyledHeader>
  );
};

export default withAuthenticationContext(Header);
