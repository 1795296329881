export const formItemLayout = {
  labelCol: {
    sm: { span: 24 },
    md: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 12 }
  }
};

export const tailFormItemLayout = {
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 12, offset: 6 }
  }
};