export interface Invoice {
  id: number;
  companyName: string;
  companyAddress: string;
  taxNumber: string;
  bank: string;
  bankAccount: string;
  customerName: string;
  customerAddress: string;
  customerPlace: string;
  customerContact: string;
  customerTaxNumber: string;
  issueDate: Date;
  dueDate: Date;
  notes: string;
  footNote: string;
  paid: boolean;
  invoiceItems: Array<InvoiceItem>
}

export interface InvoiceItem {
  description: string;
  quantity: number;
  priceWithoutVat: number;
  taxPercent: number;
}

export const INVOICES_FETCHED_ACTION_TYPE = 'INVOICE@INVOICES_FETCHED';
export const INVOICE_ADDED_ACTION_TYPE = 'INVOICE@INVOICE_ADDED';
export const INVOICE_UPDATED_ACTION_TYPE = 'INVOICE@INVOICE_UPDATE';
export const INVOICE_DELETED_ACTION_TYPE = 'INVOICE@INVOICE_DELETE';

export interface InvoicesFetchedAction {
  type: typeof INVOICES_FETCHED_ACTION_TYPE;
  invoices: Array<Invoice>;
}

export interface InvoiceUpdatedAction {
  type: typeof INVOICE_UPDATED_ACTION_TYPE;
  invoice: Invoice;
}

export interface InvoiceAddedAction {
  type: typeof INVOICE_ADDED_ACTION_TYPE;
  invoice: Invoice;
}

export interface InvoiceDeletedAction {
  type: typeof INVOICE_DELETED_ACTION_TYPE;
  id: number;
}

export type InvoiceAction = InvoicesFetchedAction
  | InvoiceAddedAction
  | InvoiceUpdatedAction
  | InvoiceDeletedAction;
