import axios from 'axios';
import { contextPath } from 'appConstants';

export interface Statistics {
  services: number;
  servicesToday: number;
  servicesPending: number;
  customers: number;
  mobiles: number;
  users: number;
  monthEarnings: number;
  yearEarnings: number;
  monthChartData: Array<ChartData>;
  yearChartData: Array<ChartData>;
}

export interface ChartData {
  name: string;
  grossRevenue: number;
  partsProfit: number;
  serviceProfit: number;
}

class DashboardService {
  async getStatistics(): Promise<Statistics> {
    return await axios.get(`${contextPath}/api/statistics`).then(response => response.data);
  }
  async getMonthlyStatistics(month: number, year: number): Promise<Array<ChartData>> {
    return await axios.get(`${contextPath}/api/reports?month=${month}&year=${year}`)
      .then(response => response.data);
  }
}

export default new DashboardService();
