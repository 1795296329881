interface Translations {
  [key: string]: any;
}

const translations: Translations = {
  en: {
    'app.name': 'Smartphone Service',
    'app.error.unauthorized': 'You are not authorized to view this resource, please login',
    'app.login.error': 'Failed credentials',
    'app.user.disabled.error': 'User is not enabled yet',
    'app.loading': 'Loading ...',
    'app.lastAdmin.error': 'You can not delete the last admin',
    copyright: '© Smartphone Service 2019',
    'page.dashboard.title': 'Dashboard',
    'page.users.title': 'Users',
    'page.customers.title': 'Customers',
    'page.mobiles.title': 'Mobiles',
    'page.parts.title': 'Parts',
    'page.services.title': 'Services',
    'page.invoices.title': 'Invoices',
    'page.reports.title': 'Reports',
    'page.devices.title': 'Devices',
    'page.resources.title': 'Resources',
    'user.logout': 'Logout',
    'page.login.title': 'Log in',
    'page.login.username.placeholder': 'Username',
    'page.login.username.error': 'Please input your Username!',
    'page.login.password.placeholder': 'Password',
    'page.login.password.error': 'Please input your Password!',
    'page.login.button': 'Log in',
    'page.login.success': 'Successfully logged in',
    'page.dashboard.title.earnings.year': 'Earnings this year',
    'page.dashboard.title.earnings.month': 'Earnings this month',
    'page.dashboard.statistic.today': 'Today',
    'page.dashboard.statistic.total': 'Total',
    'page.dashboard.statistic.month': 'this month',
    'page.dashboard.statistic.year': 'this year',
    'page.dashboard.statistic.services.total.title': 'Interventions',
    'page.dashboard.statistic.services.today.title': 'Interventions today',
    'page.dashboard.statistic.services.pending.title': 'Unfinished interventions',
    'page.dashboard.statistic.customers.title': 'Customers',
    'page.dashboard.statistic.mobiles.title': 'Mobiles',
    'page.dashboard.statistic.users.title': 'Users',
    'page.dashboard.statistic.earnings.title': 'Earnings',
    'page.dashboard.week.1': 'First week (01-06)',
    'page.dashboard.week.2': 'Second week (07-13)',
    'page.dashboard.week.3': 'Third week (14-20)',
    'page.dashboard.week.4': 'Fourth week (21-27)',
    'page.dashboard.week.5': 'Fifth week (28+)',
    'page.dashboard.bar.grossRevenue': 'Gross revenue',
    'page.dashboard.bar.partsProfit': 'Parts revenue',
    'page.dashboard.bar.serviceProfit': 'Service revenue',
    'page.customers.add.title': 'Add new customer',
    'page.customers.edit.title': 'Editing customer with id: {id}',
    'page.customers.column.name': 'Name',
    'page.customers.column.address': 'Address',
    'page.customers.column.taxNumber': 'Tax Number',
    'page.customers.column.contacts': 'Contacts',
    'page.customers.column.actions': 'Actions',
    'page.customers.form.name': 'Name',
    'page.customers.form.name.placeholder': 'E.g. John Doe',
    'page.customers.form.name.error': 'Name is required',
    'page.customers.form.address': 'Address',
    'page.customers.form.address.placeholder': 'E.g. Some Street, XX',
    'page.customers.form.address.error': 'Address is required',
    'page.customers.form.taxNumber': 'Tax number',
    'page.customers.form.taxNumber.placeholder': 'E.g. 2313123123',
    'page.customers.form.taxNumber.error': 'Tax number is required',
    'page.customers.form.contacts': 'Contacts',
    'page.customers.form.contacts.error': 'Contact must not be blank when added',
    'page.customers.form.contacts.placeholder': 'Please enter phone numbers or email for contact ',
    'page.customers.form.contacts.add': 'Add contact',
    'page.customers.form.button.submit': 'Submit',
    'page.customers.action.delete': 'Delete customer',
    'page.customers.action.edit': 'Edit customer',
    'page.customers.modal.delete.title': 'Deleting customer',
    'page.customers.modal.delete.text': 'Are you sure you want to delete the customer?',
    'page.parts.add.title': 'Add new part',
    'page.parts.edit.title': 'Editing part with id: {id}',
    'page.parts.column.name': 'Name',
    'page.parts.column.purchasePrice': 'Purchase price',
    'page.parts.column.sellPrice': 'Sell price',
    'page.parts.column.onStock': 'On stock',
    'page.parts.column.category': 'Category',
    'page.parts.column.compatibleWith': 'Compatible with',
    'page.parts.column.actions': 'Actions',
    'page.parts.action.delete': 'Delete part',
    'page.parts.action.edit': 'Edit part',
    'page.parts.modal.delete.title': 'Delete part?',
    'page.parts.modal.delete.text': 'Are you sure you want to delete the part?',
    'page.parts.form.name': 'Name',
    'page.parts.form.name.error': 'Name is required',
    'page.parts.form.partCategory': 'Part category',
    'page.parts.form.partCategory.placeholder': 'Please choose part category',
    'page.parts.form.purchasePrice': 'Purchase price',
    'page.parts.form.purchasePrice.error': 'Purchase price is required',
    'page.parts.form.sellPrice': 'Sell price',
    'page.parts.form.sellPrice.error': 'Sell price is required',
    'page.parts.form.onStock': 'On stock',
    'page.parts.form.onStock.error': 'On stock is required',
    'page.parts.form.compatibleWith': 'Compatible with',
    'page.parts.form.compatibleWith.placeholder': 'Start typing compatible devices',
    'page.parts.form.button.submit': 'Submit',
    'page.parts.categories.title': 'Categories',
    'page.parts.categories.add.title': 'Add new category',
    'page.parts.categories.edit.title': 'Editing category with id: {id}',
    'page.parts.categories.column.name': 'Category Name',
    'page.parts.categories.column.actions': 'Actions',
    'page.parts.categories.form.name': 'Name',
    'page.parts.categories.form.name.error': 'Category name is mandatory',
    'page.parts.categories.form.button.submit': 'Submit',
    'page.parts.categories.action.delete': 'Delete category',
    'page.parts.categories.action.edit': 'Edit category',
    'page.devices.add.title': 'Add new device',
    'page.devices.edit.title': 'Editing device with id: {id}',
    'page.devices.column.manufacturer': 'Manufacturer',
    'page.devices.column.model': 'Model',
    'page.devices.column.deviceName': 'Device name',
    'page.devices.column.actions': 'Actions',
    'page.devices.action.delete': 'Delete device',
    'page.devices.action.edit': 'Edit device',
    'page.devices.modal.delete.title': 'Delete device?',
    'page.devices.modal.delete.text': 'Are you sure you want to delete the device?',
    'page.devices.form.manufacturer': 'Manufacturer',
    'page.devices.form.manufacturer.error': 'Manufacturer is required',
    'page.devices.form.model': 'Model',
    'page.devices.form.model.error': 'Model is required',
    'page.devices.form.deviceName': 'Device name',
    'page.devices.form.deviceName.error': 'Device name is required',
    'page.devices.form.button.submit': 'Submit',
    'page.interventions.add.title': 'Add intervention',
    'page.interventions.edit.title': 'Editing intervention with id: {id}',
    'page.interventions.column.interventionNumber': 'No',
    'page.interventions.column.customerName': 'Customer',
    'page.interventions.column.customerContact': 'Contact',
    'page.interventions.column.mobileModel': 'Mobile',
    'page.interventions.column.agreedPrice': 'Agreed price',
    'page.interventions.column.state': 'State',
    'page.interventions.state.pickedUp': 'Picked up',
    'page.interventions.state.notPickedUp': 'Not picked up',
    'page.interventions.column.actions': 'Actions',
    'page.interventions.column.search.placeholder': 'Search by id, name, phone, model and imei',
    'page.interventions.button.search': 'Search',
    'page.interventions.button.reset': 'Reset filters',
    'page.interventions.action.delete': 'Delete intervention',
    'page.interventions.action.print': 'Print intervention',
    'page.interventions.action.edit': 'Edit intervention',
    'page.interventions.modal.delete.title': 'Delete intervention?',
    'page.interventions.modal.delete.text': 'Are you sure you want to delete the intervention?',
    'page.interventions.form.title.customer': 'Customer',
    'page.interventions.form.customerName': 'Full name',
    'page.interventions.form.customerName.placeholder': 'Person\'s full name',
    'page.interventions.form.customerContact': 'Contact',
    'page.interventions.form.customerContact.placeholder': 'Person\'s contact',
    'page.interventions.form.title.mobile': 'Mobile',
    'page.interventions.form.mobile': 'Mobile',
    'page.interventions.form.mobile.placeholder': 'Choose person\'s mobile',
    'page.interventions.form.mobile.error': 'Mobile is required in order to make an intervention',
    'page.interventions.form.imei': 'IMEI',
    'page.interventions.form.imei.placeholder': 'Enter serial number of mobile',
    'page.interventions.form.unlockCode': 'Unlock code',
    'page.interventions.form.unlockCode.placeholder': 'Enter unlock code for the mobile, if any',
    'page.interventions.form.title.agreed': 'Agreed details',
    'page.interventions.form.interventionDate': 'Drop off date',
    'page.interventions.form.agreedPrice': 'Agreed price',
    'page.interventions.form.problemDescription': 'Problem description',
    'page.interventions.form.note': 'Notes',
    'page.interventions.form.title.details': 'Intervention details',
    'page.interventions.form.pickUpDate': 'Pick up date',
    'page.interventions.form.interventionDetails': 'Intervention Details',
    'page.interventions.form.usedParts': 'Used parts',
    'page.interventions.form.usedParts.add': 'Add used part',
    'page.interventions.form.usedParts.part.placeholder': 'Select used part',
    'page.interventions.form.usedParts.partId.error': 'If added, used part is mandatory',
    'page.interventions.form.usedParts.quantity.placeholder': 'Qty',
    'page.interventions.form.usedParts.quantity.error': 'Quantity is required',
    'page.interventions.form.price': 'Price',
    'page.interventions.form.status': 'Status',
    'page.interventions.form.status.OPEN': 'Open',
    'page.interventions.form.status.FIXED': 'Fixed',
    'page.interventions.form.status.NOT_FIXABLE': 'Not fixable',
    'page.interventions.form.status.WORKING': 'Working',
    'page.interventions.form.button.submit': 'Submit',
    'page.interventions.form.button.reset': 'Reset',
    'page.interventions.form.button.new': 'New',
    'page.interventions.details.title': 'Intervention details',
    'page.interventions.details.customer.name': 'Customer name',
    'page.interventions.details.customer.contact': 'Customer contact',
    'page.interventions.details.mobile.model': 'Mobile model',
    'page.interventions.details.mobile.imei': 'IMEI',
    'page.interventions.details.mobile.unlockCode': 'Unlock code',
    'page.interventions.details.agreed.interventionDate': 'Intervention date',
    'page.interventions.details.agreed.price': 'Agreed price',
    'page.interventions.details.agreed.problemDescription': 'Problem Description',
    'page.interventions.details.agreed.note': 'Note',
    'page.interventions.details.pickUpDate': 'Pickup date',
    'page.interventions.details.interventionDetails': 'Intervention details',
    'page.interventions.details.usedParts': 'Used parts',
    'page.interventions.details.price': 'Price',
    'page.interventions.details.status': 'Status',
    'page.interventions.details.status.OPEN': 'Open',
    'page.interventions.details.status.FIXED': 'Fixed',
    'page.interventions.details.status.NOT_FIXABLE': 'Not fixable',
    'page.interventions.details.status.WORKING': 'Working',
    'page.invoices.add.title': 'Add invoice',
    'page.invoices.edit.title': 'Editing invoice with id: {id}',
    'page.invoices.column.invoiceNumber': 'No.',
    'page.invoices.column.customerName': 'Customer name',
    'page.invoices.column.customerContact': 'Customer contact',
    'page.invoices.column.customerAddress': 'Customer address',
    'page.invoices.column.dueDate': 'Due date',
    'page.invoices.column.paid': 'Status',
    'page.invoices.column.actions': 'Actions',
    'page.invoices.status.PAID': 'Paid',
    'page.invoices.status.NOT_PAID': 'Not paid',
    'page.invoices.action.delete': 'Delete invoice',
    'page.invoices.action.print': 'Print invoice',
    'page.invoices.action.edit': 'Edit invoice',
    'page.invoices.form.title.customer': 'Customer',
    'page.invoices.form.customerName': 'Name',
    'page.invoices.form.customerName.placeholder': 'Enter customer name',
    'page.invoices.form.customerContact': 'Contact',
    'page.invoices.form.customerContact.placeholder': 'Enter customer contact',
    'page.invoices.form.customerAddress': 'Address',
    'page.invoices.form.customerAddress.placeholder': 'Enter customer address',
    'page.invoices.form.customerPlace': 'City and Country',
    'page.invoices.form.customerPlace.placeholder': 'Please enter the city and country of the customer',
    'page.invoices.form.customerTaxNumber': 'Tax number',
    'page.invoices.form.customerTaxNumber.placeholder': 'Enter customer tax number',
    'page.invoices.form.title.details': 'Details',
    'page.invoices.form.issueDate': 'Issue date',
    'page.invoices.form.dueDate': 'Due date',
    'page.invoices.form.notes': 'Notes',
    'page.invoices.form.footNote': 'Footnote',
    'page.invoices.form.invoiceItems': 'Invoice items',
    'page.invoices.form.invoiceItems.add': 'Add invoice item',
    'page.invoices.form.invoiceItems.description.placeholder': 'Description',
    'page.invoices.form.invoiceItems.quantity.placeholder': 'Quantity',
    'page.invoices.form.invoiceItems.priceWithoutVat.placeholder': 'Price w/o VAT',
    'page.invoices.form.invoiceItems.taxPercent.placeholder': 'VAT',
    'page.invoices.form.status': 'Status',
    'page.invoices.form.status.PAID': 'Paid',
    'page.invoices.form.status.NOT_PAID': 'Not paid',
    'page.invoices.form.button.submit': 'Submit',
    'page.resources.add.title': 'Add new resource',
    'page.resources.edit.title': 'Editing resource with id: {id}',
    'page.resources.column.key': 'Key',
    'page.resources.column.language': 'Language',
    'page.resources.column.value': 'Value',
    'page.resources.column.actions': 'Actions',
    'page.resources.action.delete': 'Delete resource',
    'page.resources.action.edit': 'Edit resource',
    'page.resources.modal.delete.title': 'Delete resource?',
    'page.resources.modal.delete.text': 'Are you sure you want to delete the resource?',
    'page.resources.form.key': 'Key',
    'page.resources.form.key.error': 'Key is required',
    'page.resources.form.language': 'Language',
    'page.resources.form.value': 'Value',
    'page.resources.form.value.error': 'Value is required',
    'page.resources.form.button.submit': 'Submit',
    'page.users.add.title': 'Add new user',
    'page.users.edit.title': 'Editing user with username: {username} ',
    'page.users.column.firstName': 'Name',
    'page.users.column.lastName': 'Last name',
    'page.users.column.username': 'Username',
    'page.users.column.roles': 'Roles',
    'page.users.column.actions': 'Actions',
    'page.users.action.edit': 'Edit user',
    'page.users.action.delete': 'Delete user',
    'page.users.action.change.password': 'Change password',
    'page.users.modal.delete.title': 'Deleting user',
    'page.users.modal.delete.text': 'Are you sure you want to delete the user?',
    'page.users.form.firstName': 'Name',
    'page.users.form.lastName': 'Last name',
    'page.users.form.username': 'Username',
    'page.users.form.username.error': 'Username is required, this is used to login',
    'page.users.form.email': 'Email',
    'page.users.form.email.error': 'Please enter valid e-mail',
    'page.users.form.roles': 'Active roles',
    'page.users.form.roles.placeholder': 'Please choose one or more roles',
    'page.users.form.button.submit': 'Submit',
    'page.users.form.button.new': 'New user',
    'page.users.form.button.reset': 'Reset',
    'page.reports.title.earnings.month': 'Earning for month:',
    'component.passwords.password': 'Password',
    'component.passwords.password.error': 'Password is required',
    'component.passwords.matchingPassword': 'Re-enter password',
    'component.passwords.error.password.length': 'Password must be minimum 8 characters',
    'component.passwords.password.mismatch': 'Passwords must match',
    'component.passwords.matchingPassword.error': 'Field is required',
    'modal.password.title': 'Change password'
  },
  mk: {
    'app.name': 'Smartphone Service',
    'app.error.unauthorized': 'Ве молиме најавете се за да можете да продолжите',
    'app.login.error': 'Неуспешна најава',
    'app.user.disabled.error': 'Неодобрен вработен',
    'app.loading': 'Вчитување ...',
    'app.lastAdmin.error': 'Не може да се избрише последниот администратор',
    copyright: '© Смартфон Сервис 2019',
    'page.dashboard.title': 'Преглед',
    'page.users.title': 'Вработени',
    'page.customers.title': 'Клиенти',
    'page.mobiles.title': 'Мобилни',
    'page.parts.title': 'Делови',
    'page.services.title': 'Налози',
    'page.invoices.title': 'Фактури',
    'page.reports.title': 'Извештаи',
    'page.devices.title': 'Уреди',
    'page.resources.title': 'Параметри',
    'user.logout': 'Одлогирај се',
    'page.login.title': 'Најава',
    'page.login.username.placeholder': 'Корисник',
    'page.login.username.error': 'Корисничкото име е задолжително',
    'page.login.password.placeholder': 'Лозинка',
    'page.login.password.error': 'Лозинката е задолжителна',
    'page.login.button': 'Најави се',
    'page.login.success': 'Успешна најава',
    'page.dashboard.title.earnings.year': 'Приходи оваа година',
    'page.dashboard.title.earnings.month': 'Приходи овој месец',
    'page.dashboard.statistic.today': 'Денес',
    'page.dashboard.statistic.total': 'Вкупно',
    'page.dashboard.statistic.month': 'овој месец',
    'page.dashboard.statistic.year': 'оваа година',
    'page.dashboard.statistic.services.total.title': 'Налози',
    'page.dashboard.statistic.services.today.title': 'Налози денес',
    'page.dashboard.statistic.services.pending.title': 'Незавршени налози',
    'page.dashboard.statistic.customers.title': 'Клиенти',
    'page.dashboard.statistic.mobiles.title': 'Мобилни',
    'page.dashboard.statistic.users.title': 'Корисници',
    'page.dashboard.statistic.earnings.title': 'Приходи',
    'page.dashboard.week.1': 'Прва недела (01-06)',
    'page.dashboard.week.2': 'Втора недела (07-13)',
    'page.dashboard.week.3': 'Трета недела (14-20)',
    'page.dashboard.week.4': 'Четврта недела (21-27)',
    'page.dashboard.week.5': 'Петта недела (28+)',
    'page.dashboard.bar.grossRevenue': 'Вкупно приход',
    'page.dashboard.bar.partsProfit': 'Приход од делови',
    'page.dashboard.bar.serviceProfit': 'Приход од работна рака',
    'page.customers.add.title': 'Нов клиент',
    'page.customers.edit.title': 'Промена на клиент со број: {id}',
    'page.customers.column.name': 'Име',
    'page.customers.column.address': 'Адреса',
    'page.customers.column.taxNumber': 'Даночен број',
    'page.customers.column.contacts': 'Контакт',
    'page.customers.column.actions': 'Акции',
    'page.customers.form.name': 'Име на фирма',
    'page.customers.form.name.placeholder': 'Пр. Телефон Сервис',
    'page.customers.form.name.error': 'Името е задолжително',
    'page.customers.form.address': 'Адреса',
    'page.customers.form.address.placeholder': 'Пр. Бул. 1 Мај, бб',
    'page.customers.form.address.error': 'Адресата е задолжително',
    'page.customers.form.taxNumber': 'Даночен број',
    'page.customers.form.taxNumber.placeholder': 'Пр. 0123456778910',
    'page.customers.form.taxNumber.error': 'Даночниот број е задолжителен',
    'page.customers.form.contacts': 'Контакт',
    'page.customers.form.contacts.placeholder': 'Телефонски број или е-пошта',
    'page.customers.form.contacts.error': 'Доколку додадете контакт, не смее да биде празен',
    'page.customers.form.contacts.add': 'Додади нов контакт',
    'page.customers.form.button.submit': 'Зачувај',
    'page.customers.action.delete': 'Бриши',
    'page.customers.action.edit': 'Промени',
    'page.customers.modal.delete.title': 'Бришење на клиент',
    'page.customers.modal.delete.text': 'Дали сте сигурни дека сакате да го избришете клиентот?',
    'page.parts.add.title': 'Додај нов дел',
    'page.parts.edit.title': 'Промена на дел со број: {id}',
    'page.parts.column.name': 'Име',
    'page.parts.column.purchasePrice': 'Набавна цена',
    'page.parts.column.sellPrice': 'Продажна цена',
    'page.parts.column.onStock': 'На залиха',
    'page.parts.column.category': 'Категорија',
    'page.parts.column.compatibleWith': 'Компатибилен со',
    'page.parts.column.actions': 'Акции',
    'page.parts.action.delete': 'Избриши дел',
    'page.parts.action.edit': 'Промени дел',
    'page.parts.modal.delete.title': 'Избриши дел?',
    'page.parts.modal.delete.text': 'Дали сте сигурни дека сакате да го избришете делот?',
    'page.parts.form.name': 'Име',
    'page.parts.form.name.error': 'Името е задолжително',
    'page.parts.form.partCategory': 'Категорија',
    'page.parts.form.partCategory.placeholder': 'Изберете категорија на делот',
    'page.parts.form.purchasePrice': 'Набавна цена',
    'page.parts.form.purchasePrice.error': 'Набавната цена е задолжителна',
    'page.parts.form.sellPrice': 'Продажна цена',
    'page.parts.form.sellPrice.error': 'Продажната цена е задолжителна',
    'page.parts.form.onStock': 'На залиха',
    'page.parts.form.onStock.error': 'Залихата е здолжителна',
    'page.parts.form.compatibleWith': 'Компатибилен со',
    'page.parts.form.compatibleWith.placeholder': 'Изберете компатибилни уреди со овој дел',
    'page.parts.form.button.submit': 'Зачувај',
    'page.parts.categories.title': 'Категории',
    'page.parts.categories.add.title': 'Додај нова категорија',
    'page.parts.categories.edit.title': 'Промена на категорија со број: {id}',
    'page.parts.categories.column.name': 'Име на категорија',
    'page.parts.categories.column.actions': 'Акции',
    'page.parts.categories.form.name': 'Име',
    'page.parts.categories.form.name.error': 'Името на категоријата е задолжително',
    'page.parts.categories.form.button.submit': 'Зачувај',
    'page.parts.categories.action.delete': 'Избриши категорија',
    'page.parts.categories.action.edit': 'Промени категорија',
    'page.devices.add.title': 'Додај нов уред',
    'page.devices.edit.title': 'Промена на уред со број: {id}',
    'page.devices.column.manufacturer': 'Производител',
    'page.devices.column.model': 'Модел',
    'page.devices.column.deviceName': 'Име на уред',
    'page.devices.column.actions': 'Акции',
    'page.devices.action.delete': 'Избриши уред',
    'page.devices.action.edit': 'Промени уред',
    'page.devices.modal.delete.title': 'Избриши уред?',
    'page.devices.modal.delete.text': 'Дали сте сигурни дека сакате да го избришете уредот?',
    'page.devices.form.manufacturer': 'Производител',
    'page.devices.form.manufacturer.error': 'Производителот е задолжителен',
    'page.devices.form.model': 'Модел',
    'page.devices.form.model.error': 'Моделот е задолжителен',
    'page.devices.form.deviceName': 'Име на уред',
    'page.devices.form.deviceName.error': 'Името на уредот е задолжително',
    'page.devices.form.button.submit': 'Зачувај',
    'page.interventions.add.title': 'Нов налог',
    'page.interventions.edit.title': 'Промена на налог со број: {id}',
    'page.interventions.column.interventionNumber': 'Број',
    'page.interventions.column.customerName': 'Клиент',
    'page.interventions.column.customerContact': 'Контакт',
    'page.interventions.column.mobileModel': 'Мобилен',
    'page.interventions.column.agreedPrice': 'Договорена цена',
    'page.interventions.column.state': 'Состојба',
    'page.interventions.state.pickedUp': 'Подигнат',
    'page.interventions.state.notPickedUp': 'Неподигнат',
    'page.interventions.column.actions': 'Акции',
    'page.interventions.column.search.placeholder': 'Барај по име, контак, борј на налог, имеи или модел',
    'page.interventions.button.search': 'Барај',
    'page.interventions.button.reset': 'Тргни филтри',
    'page.interventions.action.delete': 'Избриши налог',
    'page.interventions.action.print': 'Печати налог',
    'page.interventions.action.edit': 'Промени налог',
    'page.interventions.modal.delete.title': 'Избриши налог?',
    'page.interventions.modal.delete.text': 'Дали сте сигурни дека сакатед да го избришете налогот?',
    'page.interventions.form.title.customer': 'Клиент',
    'page.interventions.form.customerName': 'Име и презиме',
    'page.interventions.form.customerName.placeholder': 'Името и презимето на клиентот',
    'page.interventions.form.customerContact': 'Контакт',
    'page.interventions.form.customerContact.placeholder': 'Телефон за контакт',
    'page.interventions.form.title.mobile': 'Мобилен',
    'page.interventions.form.mobile': 'Мобилен',
    'page.interventions.form.mobile.placeholder': 'Изберете го мобилниот за сервисирање',
    'page.interventions.form.mobile.error': 'Мобилниот е задолжително поле при налог',
    'page.interventions.form.imei': 'IMEI',
    'page.interventions.form.imei.placeholder': 'Внесете го IMEI-то на телефонот',
    'page.interventions.form.unlockCode': 'Код за отклучување',
    'page.interventions.form.unlockCode.placeholder': 'Внесете код за отклучување доколку има сетирано',
    'page.interventions.form.title.agreed': 'Договорени детали',
    'page.interventions.form.interventionDate': 'Дата на оставање',
    'page.interventions.form.agreedPrice': 'Договорена цена',
    'page.interventions.form.problemDescription': 'Опис на проблемот',
    'page.interventions.form.note': 'Забелешки',
    'page.interventions.form.title.details': 'Детали за налогот',
    'page.interventions.form.pickUpDate': 'Дата на подигнување',
    'page.interventions.form.interventionDetails': 'Детали за поправката',
    'page.interventions.form.usedParts': 'Искористени делови',
    'page.interventions.form.usedParts.add': 'Додадете искористени делови',
    'page.interventions.form.usedParts.part.placeholder': 'Изберете дел',
    'page.interventions.form.usedParts.partId.error': 'Доколку е додаден дел, тој е задолжителен',
    'page.interventions.form.usedParts.quantity.placeholder': 'Кол.',
    'page.interventions.form.usedParts.quantity.error': 'Количината е задолжителна',
    'page.interventions.form.submit': 'Зачувај налог',
    'page.interventions.details.title': 'Детали за налог',
    'page.interventions.details.customer.name': 'Име на клиент',
    'page.interventions.details.customer.contact': 'Контакт на клиент',
    'page.interventions.details.mobile.model': 'Модел на мобилен',
    'page.interventions.details.mobile.imei': 'IMEI',
    'page.interventions.details.mobile.unlockCode': 'Код за отклучување',
    'page.interventions.details.agreed.interventionDate': 'Дата на оставање',
    'page.interventions.details.agreed.price': 'Договорена цена',
    'page.interventions.details.agreed.problemDescription': 'Опис на проблемот',
    'page.interventions.details.agreed.note': 'Забелешки',
    'page.interventions.details.pickUpDate': 'Дата на подигнување',
    'page.interventions.details.interventionDetails': 'Детали за поправката',
    'page.interventions.details.usedParts': 'Искористени делови',
    'page.interventions.details.price': 'Цена',
    'page.interventions.details.status': 'Статус',
    'page.interventions.details.status.OPEN': 'Отворен',
    'page.interventions.details.status.FIXED': 'Поправен',
    'page.interventions.details.status.NOT_FIXABLE': 'Не се поправа',
    'page.interventions.details.status.WORKING': 'Исправен',
    'page.interventions.form.price': 'Цена',
    'page.interventions.form.status': 'Статус',
    'page.interventions.form.status.OPEN': 'Отворен',
    'page.interventions.form.status.FIXED': 'Поправен',
    'page.interventions.form.status.NOT_FIXABLE': 'Не се поправа',
    'page.interventions.form.status.WORKING': 'Исправен',
    'page.interventions.form.button.submit': 'Зачувај',
    'page.interventions.form.button.reset': 'Ресетирај',
    'page.interventions.form.button.new': 'Нов сервис',
    'page.invoices.add.title': 'Нова фактура',
    'page.invoices.edit.title': 'Промена на фактура со број: {id}',
    'page.invoices.column.invoiceNumber': 'Реден број',
    'page.invoices.column.customerName': 'Име на клиент',
    'page.invoices.column.customerContact': 'Контакт',
    'page.invoices.column.customerAddress': 'Адреса',
    'page.invoices.column.dueDate': 'Дата на доспевање',
    'page.invoices.column.paid': 'Статус',
    'page.invoices.column.actions': 'Акции',
    'page.invoices.action.delete': 'Избриши',
    'page.invoices.action.print': 'Печати',
    'page.invoices.action.edit': 'Промени',
    'page.invoices.status.PAID': 'Платена',
    'page.invoices.status.NOT_PAID': 'Неплатена',
    'page.invoices.form.title.customer': 'Килент',
    'page.invoices.form.customerName': 'Име',
    'page.invoices.form.customerName.placeholder': 'Внесете име на клиентот',
    'page.invoices.form.customerContact': 'Контакт',
    'page.invoices.form.customerContact.placeholder': 'Телефон / Е-пошта',
    'page.invoices.form.customerAddress': 'Адреса',
    'page.invoices.form.customerAddress.placeholder': 'Внесете адреса на клиентот',
    'page.invoices.form.customerPlace': 'Град и држава',
    'page.invoices.form.customerPlace.placeholder': 'Внесете град и држава на клиентот',
    'page.invoices.form.customerTaxNumber': 'Даночен број',
    'page.invoices.form.customerTaxNumber.placeholder': 'Внесете даночен број на клиентот',
    'page.invoices.form.title.details': 'Детали',
    'page.invoices.form.issueDate': 'Дата на издавање',
    'page.invoices.form.dueDate': 'Дата на доспевање',
    'page.invoices.form.notes': 'Забелешки',
    'page.invoices.form.footNote': 'Фуснота',
    'page.invoices.form.invoiceItems': 'Ставки',
    'page.invoices.form.invoiceItems.add': 'Додадете ставка',
    'page.invoices.form.invoiceItems.description.placeholder': 'Опис',
    'page.invoices.form.invoiceItems.quantity.placeholder': 'Количина',
    'page.invoices.form.invoiceItems.priceWithoutVat.placeholder': 'Цена без ДДВ',
    'page.invoices.form.invoiceItems.taxPercent.placeholder': 'ДДВ',
    'page.invoices.form.status': 'Статус',
    'page.invoices.form.status.PAID': 'Платена',
    'page.invoices.form.status.NOT_PAID': 'Неплатена',
    'page.invoices.form.button.submit': 'Зачувај',
    'page.resources.add.title': 'Додај нов параметар',
    'page.resources.edit.title': 'Промена на параметар со број: {id}',
    'page.resources.column.key': 'Клуч',
    'page.resources.column.language': 'Јазик',
    'page.resources.column.value': 'Вредност',
    'page.resources.column.actions': 'Акции',
    'page.resources.action.delete': 'Избриши параметар',
    'page.resources.action.edit': 'Промени параметар',
    'page.resources.modal.delete.title': 'Избриши параметар?',
    'page.resources.modal.delete.text': 'Дали сте сигурни дека сакате да го избришете параметарот?',
    'page.resources.form.key': 'Клуч',
    'page.resources.form.key.error': 'Клучот е задолжително',
    'page.resources.form.language': 'Јазик',
    'page.resources.form.value': 'Вредност',
    'page.resources.form.value.error': 'Вредноста е задолжителна',
    'page.resources.form.button.submit': 'Зачувај',
    'page.users.add.title': 'Нов вработен',
    'page.users.edit.title': 'Промена на вработен со корисничко име: {username} ',
    'page.users.column.firstName': 'Име',
    'page.users.column.lastName': 'Презиме',
    'page.users.column.username': 'Корисничко име',
    'page.users.column.roles': 'Улоги',
    'page.users.column.actions': 'Акции',
    'page.users.action.edit': 'Промени вработен',
    'page.users.action.delete': 'Избриши вработен',
    'page.users.action.change.password': 'Промени лозинка',
    'page.users.modal.delete.title': 'Бришење на вработен',
    'page.users.modal.delete.text': 'Дали сте сигурни дека сакате да го избришете вработениот?',
    'page.users.form.firstName': 'Име',
    'page.users.form.lastName': 'Презиме',
    'page.users.form.username': 'Корисничко име',
    'page.users.form.username.error': 'Корисничкото име е задолжително, со него потоа се најавувате',
    'page.users.form.email': 'Е-пошта',
    'page.users.form.email.error': 'Внесете валидна е-пошта',
    'page.users.form.roles': 'Активни улоги',
    'page.users.form.roles.placeholder': 'Изберете една или повеќе улоги',
    'page.users.form.button.submit': 'Зачувај',
    'page.users.form.button.new': 'Нов корисник',
    'page.users.form.button.reset': 'Ресетирај',
    'page.reports.title.earnings.month': 'Приходи за месец:',
    'component.passwords.password': 'Лозинка',
    'component.passwords.password.error': 'Лозинката е задолжителна',
    'component.passwords.error.password.length': 'Лозинката мора да е минимум 8 карактери',
    'component.passwords.error.password.mismatch': 'Лозинките во двете полиња мора да се совпаѓаат',
    'component.passwords.matchingPassword': 'Потврдете лозинка',
    'component.passwords.matchingPassword.error': 'Задолжително поле',
    'modal.password.title': 'Промена на лозинка'
  }
};

export default translations;
