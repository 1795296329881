import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import CustomersTable from 'customer/CustomersTable';
import CustomerForm from '../customer/CustomerForm';
import { Typography } from 'antd';
import { useMessageSource } from 'react-message-source';
import CustomerService from 'customer/CustomerService';
import { Customer } from 'reducers/customer/types';
import { useStateValue } from 'reducers/poorMansRedux';
import {
  customerAddedAction,
  customerDeletedAction,
  customersFetchedAction,
  customerUpdatedAction
} from 'reducers/customer/customerReducer';

const Customers: React.FC<RouteComponentProps> = () => {

  const [ { customers }, dispatch ] = useStateValue();
  const [editingCustomer, setEditingCustomer] = useState<Customer>();
  const { getMessage, getMessageWithNamedParams } = useMessageSource('page.customers');

  const handleEditCustomer = (id: number) => {
    const customerById = customers.find(customer => customer.id === id);
    setEditingCustomer(customerById);
  };

  const handleDeleteCustomer = (id: number) => {
    return CustomerService.deleteCustomer(id).then(() => {
      return dispatch(customerDeletedAction(id));
    });
  };

  const handleSaveCustomer = (customer: Customer): Promise<void | number> => {
    if (customer.id) {
      return CustomerService.updateCustomer(customer).then(() => {
        setEditingCustomer(undefined);
        dispatch(customerUpdatedAction(customer));
      });
    } else {
      return CustomerService.createCustomer(customer).then(id => {
        customer.id = id;
        dispatch(customerAddedAction(customer));
      });
    }
  };

  useEffect(() => {
    CustomerService.getCustomers().then(customers => {
      dispatch(customersFetchedAction(customers));
    });
  }, [dispatch]);

  const formKey = editingCustomer ? editingCustomer.id : 'new';

  const title = editingCustomer
    ? getMessageWithNamedParams('edit.title', { id: editingCustomer.id})
    : getMessage('add.title');

  return (
    <>
      <CustomersTable
        customers={customers}
        onEditCustomer={handleEditCustomer}
        onDeleteCustomer={handleDeleteCustomer}
      />
      <Typography.Title level={2}>{title}</Typography.Title>
      <CustomerForm key={formKey} selectedCustomer={editingCustomer} onSaveCustomer={handleSaveCustomer}/>
    </>
  );
};

export default Customers;
