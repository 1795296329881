import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import UsersTable from 'user/UsersTable';
import UserForm from '../user/UserForm';
import { Typography } from 'antd';
import { useMessageSource } from 'react-message-source';
import UserService from 'user/UserService';
import { ChangePasswordValues, Role, User } from 'reducers/user/types';
import { useStateValue } from 'reducers/poorMansRedux';
import {
  userAddedAction,
  userDeletedAction,
  usersFetchedAction,
  userUpdatedAction
} from 'reducers/user/userReducer';

const Users: React.FC<RouteComponentProps> = () => {

  const [ { users }, dispatch ] = useStateValue();
  const [editingUser, setEditingUser] = useState<User>();
  const [userRoles, setUserRoles] = useState<Array<Role>>([]);
  const { getMessage, getMessageWithNamedParams } = useMessageSource('page.users');

  const handleEditUser = (id: number) => {
    const userById = users.find(user => user.id === id);
    setEditingUser(userById);
  };

  const handleDeleteUser = (id: number) => {
    return UserService.deleteUser(id).then(() => {
      return dispatch(userDeletedAction(id));
    });
  };

  const handleSaveUser = (user: User): Promise<void | number> => {
    if (user.id) {
      return UserService.updateUser(user).then((updatedUser) => {
        setEditingUser(undefined);
        dispatch(userUpdatedAction(updatedUser));
      });
    } else {
      return UserService.createUser(user).then(newUser => {
        dispatch(userAddedAction(newUser));
      });
    }
  };

  const handleChangePassword = (userId: number, values: ChangePasswordValues) => {
    return UserService.changePassword(userId, values);
  };

  const handleNewClicked = () => {
    setEditingUser(undefined);
  };

  useEffect(() => {
    UserService.getUsers().then(users => {
      dispatch(usersFetchedAction(users));
    });
    UserService.getUserRoles().then(userRoles => {
      setUserRoles(userRoles);
    })
  }, [dispatch]);

  const formKey = editingUser ? editingUser.id : 'new';

  const title = editingUser
    ? getMessageWithNamedParams('edit.title', { username: editingUser.username})
    : getMessage('add.title');

  return (
    <>
      <UsersTable
        users={users}
        onEditUser={handleEditUser}
        onDeleteUser={handleDeleteUser}
        onChangePassword={handleChangePassword}
      />
      <Typography.Title level={2}>{title}</Typography.Title>
      <UserForm
        key={formKey}
        selectedUser={editingUser}
        onSaveUser={handleSaveUser}
        roles={userRoles}
        onNewClicked={handleNewClicked}
      />
    </>
  );
};

export default Users;
