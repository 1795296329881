import React from 'react';
import styled, { ThemeProps, ThemeConsumer } from 'styled-components';
import { useMessageSource } from 'react-message-source';
import { Icon } from 'antd';
import { Link, Match, MatchRenderProps } from '@reach/router';
import { Theme } from 'theme/theme';
import { IconProps } from 'antd/lib/icon';
import { allPages } from 'pages/routes';

interface ActiveLinkProps {
  active: boolean;
}

const StyledLink = styled(Link)<ThemeProps<Theme> & ActiveLinkProps>`
  display: flex;
  align-items: center;
  padding: 12px 50px 12px 24px;
  text-decoration: none;
  color: ${props => (props.active ? props.theme.background : props.theme.primary)};
  background-color: ${props => (props.active ? props.theme.primary : 'transparent')};
  &:hover {
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.background};
  }
  i {
    padding: 5px;
  }
`;

const NavigationItem = ({ to, children }: any) => (
  <Match path={`${to}`}>
    {(props: MatchRenderProps<any>) => (
      <StyledLink active={props.match} to={to}>
        {children}
      </StyledLink>
    )}
  </Match>
);

const BlueIcon = (props: IconProps) => (
  <ThemeConsumer>{theme => <Icon twoToneColor={theme.primary} {...props} />}</ThemeConsumer>
);

const SideBar: React.FC = () => {
  const { getMessage } = useMessageSource();
  return (
    <nav>
      {allPages
        .filter(route => !route.hidden)
        .map(route => (
          <NavigationItem to={route.path} key={route.path}>
            <BlueIcon type={route.iconType} />
            {getMessage(route.title)}
          </NavigationItem>
        ))}
    </nav>
  );
};

export default SideBar;
