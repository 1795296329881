import axios from 'axios';
import { contextPath } from 'appConstants';
import { Resource } from 'reducers/resource/types';

class ResourceService {
  async getResources(): Promise<Array<Resource>> {
    return await axios.get(`${contextPath}/api/resources`).then(response => response.data);
  }

  async createResource(resource: Resource): Promise<number> {
    return await axios.post(`${contextPath}/api/resources`, { ...resource }).then(response => response.data);
  }

  async updateResource(resource: Resource): Promise<void> {
    return await axios.put(`${contextPath}/api/resources`, { ...resource }).then(response => response.data);
  }

  async deleteResource(id: number): Promise<void> {
    return await axios.delete(`${contextPath}/api/resources/${id}`).then(response => response.data);
  }
}

export default new ResourceService();
