import React, { FormEvent } from 'react';

import { Button, Form, Input } from 'antd';
import { useMessageSource } from 'react-message-source';
import { FormComponentProps } from 'antd/lib/form';
import { formItemLayout, tailFormItemLayout } from 'theme/formLayout';
import { Resource } from 'reducers/resource/types';

interface ResourceForm extends FormComponentProps {
  selectedResource?: Resource;
  onSaveResource: (resource: Resource) => Promise<void | number>;
}

const ResourceForm: React.FunctionComponent<ResourceForm> = (props) => {

  const { getMessage } = useMessageSource('page.resources.form');

  const { selectedResource, onSaveResource } = props;
  const { getFieldDecorator } = props.form;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const deviceData = values;
        if (selectedResource) {
          deviceData.id = selectedResource.id;
        }
        onSaveResource(deviceData).then(() => props.form.resetFields());
      }
    });
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label={getMessage('key')} hasFeedback>
        {getFieldDecorator('key', {
          initialValue: selectedResource && selectedResource.key,
          rules: [
            {
              required: true,
              message: getMessage('key.error')
            }
          ]
        })(<Input/>)}
      </Form.Item>
      <Form.Item label={getMessage('language')} hasFeedback>
        {getFieldDecorator('language', {
          initialValue: selectedResource && selectedResource.language
        })(<Input/>)}
      </Form.Item>
      <Form.Item label={getMessage('value')} hasFeedback>
        {getFieldDecorator('value', {
          initialValue: selectedResource && selectedResource.value,
          rules: [
            {
              required: true,
              message: getMessage('value.error')
            }
          ]
        })(<Input/>)}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          {getMessage('button.submit')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create<ResourceForm>({ name: 'resourceForm' })(ResourceForm);
