import React, { useState } from 'react';
import InterventionService  from './InterventionService';
import { Button, Modal, Input, Table, Tag, Icon } from 'antd';
import { useMessageSource } from 'react-message-source';
import { RouteComponentProps } from '@reach/router';
import { Intervention } from 'reducers/intervention/types';
import { useStateValue } from 'reducers/poorMansRedux';
import { FilterDropdownProps } from 'antd/es/table';

interface InterventionsTableProps extends RouteComponentProps {
  interventions: Array<Intervention>;
  onDeleteIntervention: (id: number) => Promise<void>;
  onEditIntervention: (id: number) => void;
}

interface SearchState {
  searchText: string;
  filterDropdownVisible: boolean;
}

export default ({navigate, interventions, onDeleteIntervention, onEditIntervention}: InterventionsTableProps) => {

  const [ {global} ] = useStateValue();
  const [state, setState] = useState({ modalVisible: false, editingId: -1 });
  const [searchState, setSearchState] = useState<Partial<SearchState>>({ searchText: '', filterDropdownVisible: false});
  const { getMessage } = useMessageSource('page.interventions');
  const searchInputRef = React.createRef<Input>();

  const handleDeleteButtonClick = (id: number | undefined) => {
    if (id) {
      setState({ modalVisible: true, editingId: id });
    }
  };

  const handleEditButtonClick = (id: number | undefined) => {
    if (id) {
      onEditIntervention(id);
    }
  };

  const handlePrintButtonClick = (id: number | undefined) => {
    if (id) {
      InterventionService.printIntervention(id, global.language).then(blob => {
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  const closeModal = () => {
    setState({ modalVisible: false, editingId: -1 });
  };

  const deleteIntervention = (id: number) => {
    if (id) {
      onDeleteIntervention(id).then(closeModal);
    }
  };

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>, setSelectedKeys: ((selectedKeys: string[]) => void) | undefined) => {
    setSearchState({ searchText: e.target.value });
    if (setSelectedKeys) {
      setSelectedKeys([e.target.value]);
    }
  };

  const handleSearch = (searchText: string | undefined, confirm: (() => void) | undefined) => {
    if (confirm) {
      confirm();
    }
    setSearchState({
      searchText
    });
  };

  const handleReset = (clearFilters: ((selectedKeys: string[]) => void) | undefined) => {
    if (clearFilters) {
      clearFilters([]);
    }
    setSearchState({ searchText: '' });
  };

  const columns = [
    {
      title: getMessage('column.interventionNumber'),
      dataIndex: 'id',
      key: 'id',
      render: (_: any, record: Intervention) => (
        <Button type="link" onClick={() => navigate && navigate(record.id.toString())}>
          {record.id}
        </Button>
      )
    },
    {
      title: getMessage('column.customerName'),
      dataIndex: 'customerName',
      key: 'customerName'
    },
    {
      title: getMessage('column.customerContact'),
      dataIndex: 'customerContact',
      key: 'customerContact'
    },
    {
      title: getMessage('column.mobileModel'),
      dataIndex: 'mobileModel',
      key: 'mobileModel'
    },
    {
      title: getMessage('column.state'),
      key: 'status',
      render: (_: any, record: Intervention) => (
        <Tag color={record.pickUpDate ? 'green' : 'red'} key={record.id}>
          {record.pickUpDate ? getMessage("state.pickedUp") : getMessage("state.notPickedUp")}
        </Tag>
      ),
      filters: [
        {
          text: getMessage("state.pickedUp"),
          value: "picked",
        },
        {
          text: getMessage("state.notPickedUp"),
          value: "notPicked",
        }
      ],
      onFilter: (value: string, record: Intervention) => "picked" === value ? !!record.pickUpDate : !record.pickUpDate,
    },
    {
      title: getMessage('column.actions'),
      key: 'action',
      render: (_: any, record: Intervention) => (
        <span key={record.id}>
          <Button type="link" size="small" onClick={(() => handleEditButtonClick(record.id))}>
            {getMessage('action.edit')}
          </Button>
          <Button type="link" size="small" onClick={() => handleDeleteButtonClick(record.id)}>
            {getMessage('action.delete')}
          </Button>
          <Button type="link" size="small" onClick={() => handlePrintButtonClick(record.id)}>
            {getMessage('action.print')}
          </Button>
        </span>
      ),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInputRef}
            placeholder={getMessage('column.search.placeholder')}
            value={searchState.searchText}
            onChange={e => onSearchInputChange(e, setSelectedKeys)}
            onPressEnter={() => handleSearch(searchState.searchText, confirm)}
            style={{ width: 400, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(searchState.searchText, confirm)}
            icon="search"
            size="small"
            style={{ width: 200, marginRight: 8 }}
          >
            {getMessage('button.search')}
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 192 }}>
            {getMessage('button.reset')}
          </Button>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <Icon type="search" style={{ color: filtered ? '#7395AE' : undefined, fontSize: '16px'}} />
      ),
      onFilter: (value: string, record: Intervention) => {
        const searchString = value.toLowerCase();
        return !!(
          record.id.toString().toLowerCase().includes(searchString)
          || (record.customerContact && record.customerContact.toString().toLowerCase().includes(searchString))
          || (record.customerName && record.customerName.toString().toLowerCase().includes(searchString))
          || (record.mobileModel && record.mobileModel.toString().toLowerCase().includes(searchString))
          || (record.imei && record.imei.toString().toLowerCase().includes(searchString))
        );
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => searchInputRef.current && searchInputRef.current.focus());
        }
      }
    }
  ];

  return (
    <>
      <Table dataSource={interventions} columns={columns} rowKey="id"/>
      <Modal
        title={getMessage('modal.delete.title')}
        style={{ top: 20 }}
        visible={state.modalVisible}
        onOk={() => deleteIntervention(state.editingId)}
        onCancel={() => closeModal()}
      >
        <p>{getMessage('modal.delete.text')}</p>
      </Modal>
    </>
  );
}
