import React, { useState } from 'react';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { Button, Form, Icon, Row, Col } from 'antd';
import { useMessageSource } from 'react-message-source';

interface PartialFormApi {
  getFieldValue(fieldName: string): any;
  getFieldDecorator<T extends Object = {}>(id: keyof T, options?: GetFieldDecoratorOptions): (node: React.ReactNode) => React.ReactNode;
  setFieldsValue(obj: Object): void;
}

interface OneOrMoreArray<T> extends Array<T> {
  0: T;
}

interface DynamicFormArrayProps extends PartialFormApi {
  arrayName: string;
  initialValue?: any;
  messagesPrefix: string;
  fields?: Array<string>;
  components: OneOrMoreArray<React.ReactNode>;
}

const DynamicFormArray: React.FunctionComponent<DynamicFormArrayProps> = (props) => {

  const { getMessage } = useMessageSource(props.messagesPrefix);
  const {
    arrayName,
    initialValue = [],
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
    components,
    fields = []
  } = props;

  const [keysCounter, setKeysCounter] = useState(initialValue.length);

  if (components.length > 1 && fields && components.length !== fields.length) {
    throw new Error("Components and fields should be same size. " +
      "They should represent the field and corresponding component to be rendered");
  }

  const fieldName = `${arrayName}_keys`;

  const remove = (k: number) => {
    const keys = getFieldValue(fieldName);
    setFieldsValue({
      [fieldName]: keys.filter((key: number) => key !== k)
    });
  };

  const add = () => {
    const keys = getFieldValue(fieldName);
    const nextKeys = keys.concat(keysCounter + 1);
    setKeysCounter(keysCounter + 1);
    setFieldsValue({
      [fieldName]: nextKeys
    });
  };

  getFieldDecorator(fieldName, { initialValue: Object.keys(initialValue) });
  const keys = getFieldValue(fieldName);

  const formItems = keys.map((key: number) => (
    components.length < 2 ? (
    <Form.Item key={key}>
        {getFieldDecorator(`${arrayName}[${key}]`, {
          initialValue: initialValue[key],
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              message: getMessage(`${arrayName}.error`)
            }
          ]
        })(props.components[0])}
        <Icon
          className="dynamic-delete-button"
          type="minus-circle-o"
          onClick={() => remove(key)}
        />
      </Form.Item>
    ) : (
      <Row key={key}>
        {fields.map((field: string, idx) => (
          <Col key={field} span={Math.floor((22 - fields.length) / fields.length)} offset={idx !== 0 ? 1 : 0}>
            <Form.Item>
              {getFieldDecorator(`${arrayName}[${key}].${field}`, {
                validateTrigger: ['onChange', 'onBlur'],
                initialValue: props.initialValue && props.initialValue[key] && props.initialValue[key][field],
                rules: [
                  {
                    required: true,
                    message: getMessage(`${arrayName}.${field}.error`)
                  }
                ]
              })(props.components[idx])}
            </Form.Item>
          </Col>
        ))}
        <Col span={1} offset={1}>
          <Icon
            key={`delete_${key}`}
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={() => remove(key)}
          />
        </Col>
      </Row>
    )
  ));

  return (
    <React.Fragment>
      {formItems}
      <Button type="dashed" onClick={add} style={{ width: '60%' }}>
        <Icon type="plus"/> {getMessage(`${arrayName}.add`)}
      </Button>
    </React.Fragment>
  )
};

export default DynamicFormArray;