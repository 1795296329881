import React, { FormEvent } from 'react';
import { Button, DatePicker, Divider, Form, Input, InputNumber, Radio, Select } from 'antd';
import { useMessageSource } from 'react-message-source';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import { formItemLayout, tailFormItemLayout } from 'theme/formLayout';
import DynamicFormArray from 'components/form/DynamicFormArray';
import { Invoice } from 'reducers/invoice/types';

interface InvoiceFormProps extends FormComponentProps {
  selectedInvoice?: Invoice;
  onSaveInvoice: (invoice: Invoice) => Promise<void>;
}

const InvoiceForm: React.FunctionComponent<InvoiceFormProps> = (props) => {

  const { getMessage } = useMessageSource('page.invoices.form');
  const { selectedInvoice, onSaveInvoice, form } = props;

  const { TextArea } = Input;
  const { getFieldValue, getFieldDecorator, setFieldsValue } = form;

  const { Option } = Select;
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { invoiceItems_keys, invoiceItems } = values;
        const invoiceData = {
          ...values,
          invoiceItems: invoiceItems_keys.map((key: number) => invoiceItems[key])
        };
        delete invoiceData.invoiceItems_keys;

        if (selectedInvoice) {
          invoiceData.id = selectedInvoice.id;
        }

        onSaveInvoice(invoiceData).then(() => form.resetFields());
      }
    });
  };

  const taxOptions = [
    <Option key="zero" value={0}>{"0%"}</Option>,
    <Option key="five" value={0.05}>{"5%"}</Option>,
    <Option key="eighteen" value={0.18}>{"18%"}</Option>,
  ];

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Divider orientation="left">{getMessage('title.customer')}</Divider>
      <Form.Item label={getMessage('customerName')} hasFeedback>
        {getFieldDecorator('customerName', {
          initialValue: selectedInvoice && selectedInvoice.customerName
        })(
          <Input placeholder={getMessage('customerName.placeholder')}/>
        )}
      </Form.Item>
      <Form.Item label={getMessage('customerContact')} hasFeedback>
        {getFieldDecorator('customerContact', {
          initialValue: selectedInvoice && selectedInvoice.customerContact
        })(
          <Input placeholder={getMessage('customerContact.placeholder')}/>
        )}
      </Form.Item>
      <Form.Item label={getMessage('customerAddress')} hasFeedback>
        {getFieldDecorator('customerAddress', {
          initialValue: selectedInvoice && selectedInvoice.customerAddress
        })(
          <Input placeholder={getMessage('customerAddress.placeholder')}/>
        )}
      </Form.Item>
      <Form.Item label={getMessage('customerPlace')} hasFeedback>
        {getFieldDecorator('customerPlace', {
          initialValue: selectedInvoice && selectedInvoice.customerPlace
        })(
          <Input placeholder={getMessage('customerPlace.placeholder')}/>
        )}
      </Form.Item>
      <Form.Item label={getMessage('customerTaxNumber')} hasFeedback>
        {getFieldDecorator('customerTaxNumber', {
          initialValue: selectedInvoice && selectedInvoice.customerTaxNumber
        })(
          <Input placeholder={getMessage('customerTaxNumber.placeholder')}/>
        )}
      </Form.Item>
      <Divider orientation="left">{getMessage('title.details')}</Divider>
      <Form.Item label={getMessage('issueDate')}>
        {getFieldDecorator('issueDate', {
          initialValue: (selectedInvoice && selectedInvoice.issueDate)
            ? moment(selectedInvoice.issueDate)
            : moment()
        })(<DatePicker format="DD.MM.YYYY"/>)}
      </Form.Item>
      <Form.Item label={getMessage('dueDate')}>
        {getFieldDecorator('dueDate', {
          initialValue: (selectedInvoice && selectedInvoice.dueDate)
            ? moment(selectedInvoice.dueDate)
            : moment()
        })(<DatePicker format="DD.MM.YYYY"/>)}
      </Form.Item>
      <Form.Item label={getMessage('notes')} hasFeedback>
        {getFieldDecorator('notes', {
          initialValue: selectedInvoice && selectedInvoice.notes
        })(<TextArea rows={3}/>)}
      </Form.Item>
      <Form.Item label={getMessage('footNote')} hasFeedback>
        {getFieldDecorator('footNote', {
          initialValue: selectedInvoice && selectedInvoice.footNote
        })(<TextArea rows={3}/>)}
      </Form.Item>
      <Form.Item label={getMessage('invoiceItems')}>
        <DynamicFormArray
          getFieldValue={getFieldValue}
          getFieldDecorator={getFieldDecorator}
          setFieldsValue={setFieldsValue}
          arrayName="invoiceItems"
          messagesPrefix="page.invoices.form"
          fields={["description", "quantity", "priceWithoutVat", "taxPercent"]}
          initialValue={selectedInvoice && selectedInvoice.invoiceItems}
          components={
            [
              <Input style={{width: '100%'}} placeholder={getMessage('invoiceItems.description.placeholder')}/>,
              <InputNumber
                style={{width: '100%'}}
                min={0}
                precision={0}
                placeholder={getMessage('invoiceItems.quantity.placeholder')}
              />,
              <InputNumber
                style={{width: '100%'}}
                min={0}
                precision={2}
                placeholder={getMessage('invoiceItems.priceWithoutVat.placeholder')}
              />,
              <Select
                optionFilterProp="children"
                style={{ width: '100%' }}
                placeholder={getMessage('invoiceItems.taxPercent.placeholder')}
              >
                {taxOptions}
              </Select>
            ]
          }
        />
      </Form.Item>
      <Form.Item label={getMessage('status')}>
        {getFieldDecorator('paid', {
          initialValue: selectedInvoice && String(selectedInvoice.paid)
        })(
          <Radio.Group>
            <Radio value="true">{getMessage('status.PAID')}</Radio>
            <Radio value="false">{getMessage('status.NOT_PAID')}</Radio>
          </Radio.Group>,
        )}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          {getMessage('button.submit')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create<InvoiceFormProps>({ name: 'invoiceForm' })(InvoiceForm);
