import React, { FormEvent } from 'react';

import { Form, Input, Button } from 'antd';
import { useMessageSource } from 'react-message-source';
import { FormComponentProps } from 'antd/lib/form';
import { formItemLayout, tailFormItemLayout } from 'theme/formLayout';
import { Customer } from 'reducers/customer/types';
import DynamicFormArray from 'components/form/DynamicFormArray';

interface CustomerFormProps extends FormComponentProps {
  selectedCustomer?: Customer;
  onSaveCustomer: (customer: Customer) => Promise<void | number>;
}

const CustomerForm: React.FunctionComponent<CustomerFormProps> = (props) => {

  const { getMessage } = useMessageSource('page.customers.form');

  const { selectedCustomer } = props;
  const { getFieldValue, getFieldDecorator, setFieldsValue } = props.form;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { contacts_keys, contacts } = values;

        const customerData: Customer = {
          name: values.name,
          address: values.address,
          taxNumber: values.taxNumber,
          contacts: contacts_keys.map((key: number) => contacts[key])
        };

        if (selectedCustomer) {
          customerData.id = selectedCustomer.id;
        }

        props.onSaveCustomer(customerData).then(() => props.form.resetFields());
      }
    });
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label={getMessage('name')} hasFeedback>
        {getFieldDecorator('name', {
          initialValue: selectedCustomer && selectedCustomer.name,
          rules: [
            {
              required: true,
              message: getMessage('name.error')
            },
          ]
        })(<Input/>)}
      </Form.Item>
      <Form.Item label={getMessage('address')} hasFeedback>
        {getFieldDecorator('address', {
          initialValue: selectedCustomer && selectedCustomer.address,
          rules: [
            {
              required: true,
              message: getMessage('address.error')
            }
          ]
        })(<Input/>)}
      </Form.Item>
      <Form.Item label={getMessage('taxNumber')} hasFeedback>
        {getFieldDecorator('taxNumber', {
          initialValue: selectedCustomer && selectedCustomer.taxNumber,
          rules: [
            {
              required: true,
              message: getMessage('taxNumber.error')
            }
          ]
        })(<Input/>)}
      </Form.Item>
      <Form.Item
        label={getMessage('contacts')}
        required={false}
        hasFeedback
      >
        <DynamicFormArray
          getFieldValue={getFieldValue}
          getFieldDecorator={getFieldDecorator}
          setFieldsValue={setFieldsValue}
          initialValue={selectedCustomer && selectedCustomer.contacts}
          arrayName="contacts"
          messagesPrefix="page.customers.form"
          components={
            [
              <Input placeholder={getMessage('contacts.placeholder')} style={{ width: '60%', marginRight: 8 }}/>
            ]
          }
        />
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          {getMessage('button.submit')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create<CustomerFormProps>({ name: 'customerForm' })(CustomerForm);
