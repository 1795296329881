import axios from 'axios';
import { contextPath } from 'appConstants';
import { ChangePasswordValues, Role, User } from 'reducers/user/types';

class UserService {
  async getUsers(): Promise<Array<User>> {
    return await axios.get(`${contextPath}/api/users`).then(response => response.data);
  }

  async getUserRoles(): Promise<Array<Role>> {
    return await axios.get(`${contextPath}/api/user-roles`).then(response => response.data);
  }

  async createUser(user: User): Promise<User> {
    return await axios.post(`${contextPath}/api/users`, { ...user }).then(response => response.data);
  }

  async updateUser(user: User): Promise<User> {
    return await axios.put(`${contextPath}/api/users`, { ...user }).then(response => response.data);
  }

  async deleteUser(id: number): Promise<void> {
    return await axios.delete(`${contextPath}/api/users/${id}`).then(response => response.data);
  }

  async changePassword(id:number, values: ChangePasswordValues): Promise<void> {
    const data = { id, password: values.password, matchingPassword: values.matchingPassword };
    return await axios.put(`${contextPath}/api/users/change-password`, data).then(response => response.data);
  }
}

export default new UserService();
