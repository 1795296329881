import {
  Customer,
  CUSTOMERS_FETCHED_ACTION_TYPE,
  CUSTOMER_ADDED_ACTION_TYPE,
  CUSTOMER_UPDATED_ACTION_TYPE,
  CUSTOMER_DELETED_ACTION_TYPE,
  CustomersFetchedAction,
  CustomerAddedAction,
  CustomerDeletedAction,
  CustomerUpdatedAction,
  CustomerAction
} from 'reducers/customer/types';
import { BOOTSTRAP_ACTION_TYPE } from 'reducers/types';

export const customersFetchedAction = (customers: Array<Customer>): CustomersFetchedAction => ({
  type: CUSTOMERS_FETCHED_ACTION_TYPE,
  customers
});

export const customerAddedAction = (customer: Customer): CustomerAddedAction => ({
  type: CUSTOMER_ADDED_ACTION_TYPE,
  customer
});

export const customerUpdatedAction = (customer: Customer): CustomerUpdatedAction => ({
  type: CUSTOMER_UPDATED_ACTION_TYPE,
  customer
});

export const customerDeletedAction = (id: number): CustomerDeletedAction => ({
  type: CUSTOMER_DELETED_ACTION_TYPE,
  id
});

const customerReducer = (state: Array<Customer>, action: CustomerAction) => {
  switch (action.type) {
    case BOOTSTRAP_ACTION_TYPE:
      return action.state.customers;
    case CUSTOMERS_FETCHED_ACTION_TYPE:
      return action.customers;
    case CUSTOMER_ADDED_ACTION_TYPE:
      return [...state, action.customer];
    case CUSTOMER_UPDATED_ACTION_TYPE:
      return state.map(customer => {
        if (customer.id === action.customer.id) {
          return action.customer;
        }
        return customer;
      });
    case CUSTOMER_DELETED_ACTION_TYPE:
      return state.filter(customer => customer.id !== action.id);
    default:
      return state;
  }
};

export default customerReducer;
