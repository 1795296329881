import React from 'react';
import styled, { ThemeProps } from 'styled-components';
import { Theme } from 'theme/theme';
import logo from 'components/logo_w200.png';

const LogoContainer = styled.div`
  width: 280px;
  height: 130px;
  background-color: ${(props: ThemeProps<Theme>) => props.theme.accent};
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  flex: 0 0 auto; /* Forces side columns to stay same width */
`;

const StyledLogo = styled.img`
  width: 200px;
  align-self: center;
`;

const Logo: React.FC = () => {
  return (
    <LogoContainer>
      <StyledLogo src={logo} alt="logo" />
    </LogoContainer>
  );
};

export default Logo;
