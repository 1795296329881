import {
  Resource,
  RESOURCES_FETCHED_ACTION_TYPE,
  RESOURCE_ADDED_ACTION_TYPE,
  RESOURCE_UPDATED_ACTION_TYPE,
  RESOURCE_DELETED_ACTION_TYPE,
  ResourcesFetchedAction,
  ResourceAddedAction,
  ResourceDeletedAction,
  ResourceUpdatedAction,
  ResourceAction
} from 'reducers/resource/types';

export const resourcesFetchedAction = (resources: Array<Resource>): ResourcesFetchedAction => ({
  type: RESOURCES_FETCHED_ACTION_TYPE,
  resources
});

export const resourceAddedAction = (resource: Resource): ResourceAddedAction => ({
  type: RESOURCE_ADDED_ACTION_TYPE,
  resource
});

export const resourceUpdatedAction = (resource: Resource): ResourceUpdatedAction => ({
  type: RESOURCE_UPDATED_ACTION_TYPE,
  resource
});

export const resourceDeletedAction = (id: number): ResourceDeletedAction => ({
  type: RESOURCE_DELETED_ACTION_TYPE,
  id
});

const resourceReducer = (state: Array<Resource>, action: ResourceAction) => {
  switch (action.type) {
    case RESOURCES_FETCHED_ACTION_TYPE:
      return action.resources;
    case RESOURCE_ADDED_ACTION_TYPE:
      return [...state, action.resource];
    case RESOURCE_UPDATED_ACTION_TYPE:
      return state.map(resource => {
        if (resource.id === action.resource.id) {
          return action.resource;
        }
        return resource;
      });
    case RESOURCE_DELETED_ACTION_TYPE:
      return state.filter(resource => resource.id !== action.id);
    default:
      return state;
  }
};

export default resourceReducer;
