import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useMessageSource } from 'react-message-source';
import PartsTable from 'part/PartsTable';
import { Col, Row, Typography } from 'antd';
import PartForm from 'part/PartForm';
import { useStateValue } from 'reducers/poorMansRedux';
import { Part } from 'reducers/part/types';
import PartService from 'part/PartService';
import { partAddedAction, partDeletedAction, partsFetchedAction, partUpdatedAction } from 'reducers/part/partReducer';
import {
  partCategoryAddedAction,
  partCategoriesFetchedAction,
  partCategoryUpdatedAction,
  partCategoryDeletedAction
} from 'reducers/part-category/partCategoryReducer';
import { PartCategory } from 'reducers/part-category/types';
import PartCategoriesTable from 'part/PartCategoriesTable';
import PartCategoryForm from 'part/PartCategoriesForm';

const Parts: React.FC<RouteComponentProps> = () => {

  const [{parts, partCategories, devices}, dispatch] = useStateValue();
  const [editingPart, setEditingPart] = useState<Part>();
  const [editingPartCategory, setEditingPartCategory] = useState<PartCategory>();
  const { getMessage, getMessageWithNamedParams } = useMessageSource('page.parts');

  const handleEditPart = (id: number) => {
    const partById = parts.find(part => part.id === id);
    setEditingPart(partById);
  };

  const handleDeletePart = (id: number) => {
    return PartService.deletePart(id).then(() => {
      return dispatch(partDeletedAction(id));
    })
  };

  const handleSavePart = (part: Part): Promise<void | number> => {
    if (part.id) {
      return PartService.updatePart(part).then((updatedPart) => {
        setEditingPart(undefined);
        dispatch(partUpdatedAction(updatedPart));
      })
    } else {
      return PartService.createPart(part).then(newPart => {
        dispatch(partAddedAction(newPart));
      })
    }
  };

  const handleEditPartCategory = (id: number) => {
    const partCategoryById = partCategories.find(partCategory => partCategory.id === id);
    setEditingPartCategory(partCategoryById);
  };

  const handleDeletePartCategory = (id: number) => {
    return PartService.deletePartCategory(id).then(() => {
      return dispatch(partCategoryDeletedAction(id));
    })
  };

  const handleSavePartCategory = (partCategory: PartCategory): Promise<void | number> => {
    if (partCategory.id) {
      return PartService.updatePartCategory(partCategory).then(() => {
        setEditingPartCategory(undefined);
        dispatch(partCategoryUpdatedAction(partCategory));
      })
    } else {
      return PartService.createPartCategory(partCategory).then(id => {
        partCategory.id = id;
        dispatch(partCategoryAddedAction(partCategory));
      })
    }
  };

  useEffect(() => {
    PartService.getParts().then(parts => {
      dispatch(partsFetchedAction(parts));
    });
    PartService.getPartCategories().then(partCategories => {
      dispatch(partCategoriesFetchedAction(partCategories));
    });
  }, [dispatch]);

  const formKey = editingPart ? editingPart.id : 'new';
  const partCategoryFormKey = editingPartCategory ? editingPartCategory.id : 'new-category';

  const partTitle = editingPart
    ? getMessageWithNamedParams('edit.title', { id: editingPart.id })
    : getMessage('add.title');

  const partCategoryTitle = editingPartCategory
    ? getMessageWithNamedParams('categories.edit.title', { id: editingPartCategory.id })
    : getMessage('categories.add.title');

  return (
    <>
      <Row>
        <Col md={14}>
          <Typography.Title level={1}>{getMessage('title')}</Typography.Title>
          <PartsTable
            parts={parts}
            onEditPart={handleEditPart}
            onDeletePart={handleDeletePart}
          />
        </Col>
        <Col offset={1} md={9}>
          <Typography.Title level={1}>{getMessage('categories.title')}</Typography.Title>
          <PartCategoriesTable
            partCategories={partCategories}
            onEditPartCategory={handleEditPartCategory}
            onDeletePartCategory={handleDeletePartCategory}
          />
        </Col>
      </Row>
      <Row>
        <Col md={14}>
          <Typography.Title level={2}>{partTitle}</Typography.Title>
          <PartForm
            key={formKey}
            devices={devices}
            partCategories={partCategories}
            selectedPart={editingPart}
            onSavePart={handleSavePart}
          />
        </Col>
        <Col offset={1} md={9}>
          <Typography.Title level={2}>{partCategoryTitle}</Typography.Title>
          <PartCategoryForm
            key={partCategoryFormKey}
            selectedPartCategory={editingPartCategory}
            onSavePartCategory={handleSavePartCategory}
          />
        </Col>
      </Row>
    </>
  );
};

export default Parts;
