import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { RouteComponentProps } from '@reach/router';
import { useMessageSource } from 'react-message-source';
import { useStateValue } from 'reducers/poorMansRedux';
import InvoicesTable from 'invoice/InvoicesTable';
import InvoiceForm from 'invoice/InvoiceForm';
import InvoiceService from 'invoice/InvoiceService';
import { Invoice } from 'reducers/invoice/types';
import {
  invoicesFetchedAction,
  invoiceAddedAction,
  invoiceUpdatedAction,
  invoiceDeletedAction
} from 'reducers/invoice/invoiceReducer';

const Invoices: React.FC<RouteComponentProps> = (props) => {

  const [{invoices}, dispatch] = useStateValue();
  const [editingInvoice, setEditingInvoice] = useState<Invoice>();
  const { getMessage, getMessageWithNamedParams } = useMessageSource('page.invoices');

  const handleEditInvoice = (id: number) => {
    const invoiceById = invoices.find(invoice => invoice.id === id);
    setEditingInvoice(invoiceById);
  };

  const handleDeleteInvoice = (id: number) => {
    return InvoiceService.deleteInvoice(id).then(() => {
      return dispatch(invoiceDeletedAction(id));
    })
  };

  const handleSaveInvoice = (invoice: Invoice): Promise<void> => {
    if (invoice.id) {
      return InvoiceService.updateInvoice(invoice).then((updatedInvoice) => {
        setEditingInvoice(undefined);
        dispatch(invoiceUpdatedAction(updatedInvoice));
      });
    } else {
      return InvoiceService.createInvoice(invoice).then(addedInvoice => {
        dispatch(invoiceAddedAction(addedInvoice));
      });
    }
  };

  useEffect(() => {
    InvoiceService.getInvoices().then(invoices => {
      dispatch(invoicesFetchedAction(invoices));
    });
  }, [dispatch]);

  const formKey = editingInvoice ? editingInvoice.id : 'new';

  const title = editingInvoice
    ? getMessageWithNamedParams('edit.title', { id: editingInvoice.id})
    : getMessage('add.title');

  return (
    <>
      <InvoicesTable
        navigate={props.navigate}
        invoices={invoices}
        onEditInvoice={handleEditInvoice}
        onDeleteInvoice={handleDeleteInvoice}
      />
      <Typography.Title level={2}>{title}</Typography.Title>
      <InvoiceForm
        key={formKey}
        selectedInvoice={editingInvoice}
        onSaveInvoice={handleSaveInvoice}
      />
    </>
  );
};

export default Invoices;
