import React, { useContext, useEffect, useState } from 'react';
import { Spin } from 'antd';
import BootstrapService from 'bootstrap/bootstrapService';
import { bootstrapApplication } from 'reducers/global/globalDataReducer';
import { useStateValue } from 'reducers/poorMansRedux';
import { AuthenticationContext } from 'security/AuthenticationContext';
import styled, { createGlobalStyle, ThemeProps } from 'styled-components';
import { Theme } from 'theme/theme';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    margin: 0;
    height: 100vh;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${(props: ThemeProps<Theme>) => props.theme.text};
    background-color: ${(props: ThemeProps<Theme>) => props.theme.background};
  }
`;

const CenteredSpin = styled(Spin)`
  height: 100vh;
  align-self: center;
  display: flex !important;
  position: inherit !important;
  align-items: center;
`;

const Bootstrapper: React.FunctionComponent<any> = (props) => {

  const authContext = useContext(AuthenticationContext);
  const [, dispatch] = useStateValue();
  const [readyForDisplay, setReadyForDisplay] = useState(false);

  useEffect(() => {
    if (authContext.principal) {
      BootstrapService.getState().then(state => {
        dispatch(bootstrapApplication(state));
        setReadyForDisplay(true);
      }).catch(() => setReadyForDisplay(true));
    } else {
      setReadyForDisplay(true)
    }
  }, [dispatch, authContext.principal]);

  const content = readyForDisplay ? props.children : <CenteredSpin size="large" />;
  return (
    <>
      <GlobalStyle/>
      {content}
    </>
  )
};

export default Bootstrapper;