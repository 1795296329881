import { Customer } from 'reducers/customer/types';
import { GlobalDataState } from 'reducers/global/types';
import { Device } from 'reducers/device/types';
import { Part } from 'reducers/part/types';
import { Intervention } from 'reducers/intervention/types';
import { Resource } from 'reducers/resource/types';
import { PartCategory } from 'reducers/part-category/types';
import { Invoice } from 'reducers/invoice/types';
import { User } from 'reducers/user/types';

export const BOOTSTRAP_ACTION_TYPE = 'BOOTSTRAP@FETCH_INITIAL_DATA';

export interface State {
  global: GlobalDataState;
  customers: Array<Customer>;
  devices: Array<Device>;
  parts: Array<Part>;
  partCategories: Array<PartCategory>;
  interventions: Array<Intervention>;
  invoices: Array<Invoice>
  resources: Array<Resource>;
  users: Array<User>;
}

export interface BootstrapAction {
  type: typeof BOOTSTRAP_ACTION_TYPE,
  state: State
}
