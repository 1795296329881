export interface User {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  matchingPassword: string;
  email: string;
  roles: Array<Role>;
}

export interface Role {
  id: number;
  name: string;
}

export interface ChangePasswordValues {
  password: string;
  matchingPassword: string;
}

export const USERS_FETCHED_ACTION_TYPE = 'USER@USERS_FETCHED';
export const USER_ADDED_ACTION_TYPE = 'USER@USER_ADDED';
export const USER_UPDATED_ACTION_TYPE = 'USER@USER_UPDATE';
export const USER_DELETED_ACTION_TYPE = 'USER@USER_DELETE';

export interface UsersFetchedAction {
  type: typeof USERS_FETCHED_ACTION_TYPE;
  users: Array<User>;
}

export interface UserUpdatedAction {
  type: typeof USER_UPDATED_ACTION_TYPE;
  user: User;
}

export interface UserAddedAction {
  type: typeof USER_ADDED_ACTION_TYPE;
  user: User;
}

export interface UserDeletedAction {
  type: typeof USER_DELETED_ACTION_TYPE;
  id: number;
}

export type UserAction = UsersFetchedAction
  | UserAddedAction
  | UserUpdatedAction
  | UserDeletedAction;
