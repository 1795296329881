import React from 'react';
import { Dropdown, Menu } from 'antd';
import mkd from './mkd.svg';
import usa from './usa.svg';
import { useStateValue } from 'reducers/poorMansRedux';
import { changeLanguageAction } from 'reducers/global/globalDataReducer';

const supportedLanguages = [
  {
    lang: 'en',
    icon: usa
  },
  {
    lang: 'mk',
    icon: mkd
  }
];

export default () => {
  
  const [ { global }, dispatch ] = useStateValue();
  const { language } = global;

  const currentSelectedLanguage = supportedLanguages
    .filter(supportedLanguage => supportedLanguage.lang === language)[0];

  const allLanguages = supportedLanguages.map(supportedLanguage => (
    <Menu.Item key={supportedLanguage.lang}>
      <img
        width={32}
        src={supportedLanguage.icon}
        onClick={() => dispatch(changeLanguageAction(supportedLanguage.lang))}
        alt={`flag_${supportedLanguage.lang}`}
      />
    </Menu.Item>
  ));

  const menu =   (
    <Menu>
      {allLanguages}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomCenter">
      <img width={32} src={currentSelectedLanguage.icon} alt={`flag_${language}`}/>
    </Dropdown>
  );
}
