import {
  Intervention,
  INTERVENTIONS_FETCHED_ACTION_TYPE,
  INTERVENTION_ADDED_ACTION_TYPE,
  INTERVENTION_UPDATED_ACTION_TYPE,
  INTERVENTION_DELETED_ACTION_TYPE,
  InterventionsFetchedAction,
  InterventionAddedAction,
  InterventionDeletedAction,
  InterventionUpdatedAction,
  InterventionAction
} from 'reducers/intervention/types';
import { BOOTSTRAP_ACTION_TYPE } from 'reducers/types';

export const interventionsFetchedAction = (interventions: Array<Intervention>): InterventionsFetchedAction => ({
  type: INTERVENTIONS_FETCHED_ACTION_TYPE,
  interventions
});

export const interventionAddedAction = (intervention: Intervention): InterventionAddedAction => ({
  type: INTERVENTION_ADDED_ACTION_TYPE,
  intervention
});

export const interventionUpdatedAction = (intervention: Intervention): InterventionUpdatedAction => ({
  type: INTERVENTION_UPDATED_ACTION_TYPE,
  intervention
});

export const interventionDeletedAction = (id: number): InterventionDeletedAction => ({
  type: INTERVENTION_DELETED_ACTION_TYPE,
  id
});

const interventionReducer = (state: Array<Intervention>, action: InterventionAction) => {
  switch (action.type) {
    case BOOTSTRAP_ACTION_TYPE:
      return action.state.interventions;
    case INTERVENTIONS_FETCHED_ACTION_TYPE:
      return action.interventions;
    case INTERVENTION_ADDED_ACTION_TYPE:
      return [...state, action.intervention];
    case INTERVENTION_UPDATED_ACTION_TYPE:
      return state.map(intervention => {
        if (intervention.id === action.intervention.id) {
          return action.intervention;
        }
        return intervention;
      });
    case INTERVENTION_DELETED_ACTION_TYPE:
      return state.filter(intervention => intervention.id !== action.id);
    default:
      return state;
  }
};

export default interventionReducer;
