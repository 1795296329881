import {
  Invoice,
  INVOICES_FETCHED_ACTION_TYPE,
  INVOICE_ADDED_ACTION_TYPE,
  INVOICE_UPDATED_ACTION_TYPE,
  INVOICE_DELETED_ACTION_TYPE,
  InvoicesFetchedAction,
  InvoiceAddedAction,
  InvoiceDeletedAction,
  InvoiceUpdatedAction,
  InvoiceAction
} from 'reducers/invoice/types';

export const invoicesFetchedAction = (invoices: Array<Invoice>): InvoicesFetchedAction => ({
  type: INVOICES_FETCHED_ACTION_TYPE,
  invoices
});

export const invoiceAddedAction = (invoice: Invoice): InvoiceAddedAction => ({
  type: INVOICE_ADDED_ACTION_TYPE,
  invoice
});

export const invoiceUpdatedAction = (invoice: Invoice): InvoiceUpdatedAction => ({
  type: INVOICE_UPDATED_ACTION_TYPE,
  invoice
});

export const invoiceDeletedAction = (id: number): InvoiceDeletedAction => ({
  type: INVOICE_DELETED_ACTION_TYPE,
  id
});

const invoiceReducer = (state: Array<Invoice>, action: InvoiceAction) => {
  switch (action.type) {
    case INVOICES_FETCHED_ACTION_TYPE:
      return action.invoices;
    case INVOICE_ADDED_ACTION_TYPE:
      return [...state, action.invoice];
    case INVOICE_UPDATED_ACTION_TYPE:
      return state.map(invoice => {
        if (invoice.id === action.invoice.id) {
          return action.invoice;
        }
        return invoice;
      });
    case INVOICE_DELETED_ACTION_TYPE:
      return state.filter(invoice => invoice.id !== action.id);
    default:
      return state;
  }
};

export default invoiceReducer;
