import { BootstrapAction } from 'reducers/types';

export interface Device {
  id?: number;
  manufacturer: string;
  model: string;
  deviceName: string;
}

export const DEVICES_FETCHED_ACTION_TYPE = 'DEVICE@DEVICES_FETCHED';
export const DEVICE_ADDED_ACTION_TYPE = 'DEVICE@DEVICE_ADDED';
export const DEVICE_UPDATED_ACTION_TYPE = 'DEVICE@DEVICE_UPDATE';
export const DEVICE_DELETED_ACTION_TYPE = 'DEVICE@DEVICE_DELETE';

export interface DevicesFetchedAction {
  type: typeof DEVICES_FETCHED_ACTION_TYPE;
  devices: Array<Device>;
}

export interface DeviceUpdatedAction {
  type: typeof DEVICE_UPDATED_ACTION_TYPE;
  device: Device;
}

export interface DeviceAddedAction {
  type: typeof DEVICE_ADDED_ACTION_TYPE;
  device: Device;
}

export interface DeviceDeletedAction {
  type: typeof DEVICE_DELETED_ACTION_TYPE;
  id: number;
}

export type DeviceAction = BootstrapAction
  | DevicesFetchedAction
  | DeviceAddedAction
  | DeviceUpdatedAction
  | DeviceDeletedAction;
