import axios from 'axios';
import { contextPath } from 'appConstants';
import { Customer } from 'reducers/customer/types';

class CustomerService {
  async getCustomers(): Promise<Array<Customer>> {
    return await axios.get(`${contextPath}/api/customers`).then(response => response.data);
  }

  async createCustomer(customer: Customer): Promise<number> {
    return await axios.post(`${contextPath}/api/customers`, { ...customer }).then(response => response.data);
  }

  async updateCustomer(customer: Customer): Promise<void> {
    return await axios.put(`${contextPath}/api/customers`, { ...customer }).then(response => response.data);
  }

  async deleteCustomer(id: number): Promise<void> {
    return await axios.delete(`${contextPath}/api/customers/${id}`).then(response => response.data);
  }
}

export default new CustomerService();
