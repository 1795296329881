import { CHANGE_LANGUAGE_ACTION_TYPE, GlobalDataState, LanguageAction } from 'reducers/global/types';
import { BOOTSTRAP_ACTION_TYPE, State } from 'reducers/types';

export const changeLanguageAction = (language: string): LanguageAction => ({
  type: CHANGE_LANGUAGE_ACTION_TYPE,
  language
});

export const bootstrapApplication = (state: State) => ({
  type: BOOTSTRAP_ACTION_TYPE,
  state
});

const globalDataReducer = (state: GlobalDataState, action: LanguageAction) => {
  switch (action.type) {
    case CHANGE_LANGUAGE_ACTION_TYPE:
      return {
        ...state,
        language: action.language
      };
    default:
      return state;
  }
};

export default globalDataReducer;
