import axios from 'axios';
import { contextPath } from 'appConstants';
import { State } from 'reducers/types';

class DashboardService {
  async getState(): Promise<State> {
    return await axios.get(`${contextPath}/api/bootstrap`).then(response => response.data);
  }
}

export default new DashboardService();